import React from 'react';
import { ItemSelector, List, EmployeeItem } from 'components';

const formatDate = (date) => new Date(date).toLocaleDateString();

const EmployeesList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Имя',
      field:  (props) => (
        <input 
          type="text"
          placeholder="Наименование"
          value={props.value}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      initialValue: ''
    },
    {
      key: 'group',
      label: 'Группы',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/admin-api/employee-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите группу клиентов"
          searchPlaceholder="Поиск группы клиентов..."
          noItemsFoundText="Группы клиентов не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.group ? [props.group] : [],
    },
    {
      key: 'stores',
      label: 'Магазины',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/admin-api/stores"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите магазин"
          searchPlaceholder="Поиск магазина..."
          noItemsFoundText="Магазины не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true},
    { key: 'username', label: 'Имя пользователя' },
    { key: 'name', label: 'Полное имя', component: (item) => <>{item.first_name} {item.last_name}</> },
    { key: 'stores', label: 'Магазины', component: (item) => <>{item.stores && item.stores.map((store) => store.name).join(', ')}</> },
    { key: 'group', label: 'Группа', component: (item) => <>{item.group && item.group.name}</> },
    { key: 'last_seen', label: 'Последний визит', component: (item) => <>{formatDate(item.last_seen)}</> },
    { key: 'created', label: 'Создан', component: (item) => <>{formatDate(item.created)}</> },
  ];

  return (
    <List
      targetUrl="/admin-api/employees"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={EmployeeItem}
    />
  );
};

export default EmployeesList;
