import { BaseApiService, apiClient } from './api';

export class OrderService extends BaseApiService {
    constructor() {
        super('/crm/admin-api/orders');
    }

    getStats(token='token') {
        return apiClient.get(`${this.endpoint}/stats`, '', token);
    }

    updateStatus(orderId, status, token='token') {
        return apiClient.patch(`${this.endpoint}/${orderId}/`, { status }, token);
    }

    updateMessage(orderId, message, token='token') {
        return apiClient.patch(`${this.endpoint}/${orderId}/`, { message }, token);
    }
}