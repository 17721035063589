import React, { useState } from 'react';
import { EmployeeGroupSelector, StoreSelector } from 'components';
import { X, Eye, EyeOff } from 'lucide-react';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';
import { crudEmployee } from 'services';
import { fr } from 'date-fns/locale';


const StoreForm = ({ store }) => {
  const { closeModal } = useLayout();
  const { showToast } = useStateContext();
  const [storeData, setEmployeeData] = useState(store || {});

  const handleSave = async () => {
    try {
      const data = {
        ...storeData,
      };
      showToast({
        title: 'Success!',
        content: 'Employee has been saved successfully!',
        cssClass: 'e-toast-success',
        icon: 'e-success toast-icons',
      });
    } catch (error) {
      console.error(error);
    }
    closeModal();
  };

  const handleInputChange = (field, value) => {
    setEmployeeData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <h2 className="text-2xl font-semibold mb-6">Создание сотрудника</h2>
      <div>
        <div className="mb-4">
          <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 mb-1">
            Наименование <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="first_name"
            placeholder="Имя"
            className="w-full px-3 py-2 border rounded-md"
            value={storeData.name || ''}
            onChange={(e) => handleInputChange('first_name', e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 mb-1">
            Описание 
          </label>
          <textarea
            type="text"
            id="last_name"
            placeholder="Фамилия"
            className="w-full px-3 py-2 border rounded-md"
            value={storeData.description || ''}
            onChange={(e) => handleInputChange('last_name', e.target.value)}
          />
        </div>

        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={handleSave}>
          Сохранить
        </button>
      </div>
    </>
  );
};

export default StoreForm;
