import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";

import { ContextProvider } from "./contexts/ContextProvider";
import { LayoutProvider } from "./contexts/LayoutContext";

// registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF1cVWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjWn9ccXRXRGVZU0J+Xg==');

ReactDOM.render(
  <ContextProvider>
    <LayoutProvider>
      <App />
    </LayoutProvider>
  </ContextProvider>,
  document.getElementById("root")
);
