import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CustomStackedBarChart = ({ data, config }) => {
  const [visibleStats, setVisibleStats] = useState(
    config.dataKeys.reduce((acc, key) => {
      acc[key] = true; 
      return acc;
    }, {})
  );

  // Toggle visibility of a bar
  const toggleStat = (stat) => {
    setVisibleStats((prev) => ({ ...prev, [stat]: !prev[stat] }));
  };

  // Custom Legend with clickable labels
  const renderLegend = (props) => {
    const { payload } = props;
    const fullPayload = config.dataKeys.map((key, index) => ({
      dataKey: key,
      value: config.labels[index],
      color: config.colors[index],
      type: 'rect', // Ensure the type is set to 'rect' for bars
    }));

    return (
      <div className="flex justify-center gap-4 mt-4">
        {fullPayload.map((entry, index) => (
          <span
            key={`legend-${index}`}
            onClick={() => toggleStat(entry.dataKey)}
            style={{
              cursor: 'pointer',
              color: visibleStats[entry.dataKey] ? entry.color : '#ccc', // Fade color when hidden
              opacity: visibleStats[entry.dataKey] ? 1 : 0.5, // Make text transparent when hidden
              textDecoration: visibleStats[entry.dataKey] ? 'none' : 'line-through', // Strikethrough when hidden
            }}
          >
            {entry.value}
          </span>
        ))}
      </div>
    );
  };

  // Custom Tooltip with percentage
  const renderTooltip = ({ payload, label }) => {
    if (!payload || !payload.length) return null;

    const total = payload.reduce((acc, curr) => acc + curr.value, 0);

    return (
      <div className="bg-white p-2 border rounded shadow-sm">
        <p className="font-semibold">{label}</p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="flex justify-between">
            <span style={{ color: entry.color }}>{entry.name}</span>
            <span>{`${entry.value} (${((entry.value / total) * 100).toFixed(2)}%)`}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={renderTooltip} />
        <Legend content={renderLegend} />
        {config.dataKeys.map((key, index) =>
          visibleStats[key] ? (
            <Bar
              key={key}
              dataKey={key}
              name={config.labels[index]}
              stackId="a"
              fill={config.colors[index]}
            />
          ) : null
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomStackedBarChart;