import React from 'react';
import { List, DocItem, ItemSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const DocsList = (props) => {
  const filterOptions = [
    {
      key: 'client',
      label: 'Клиент',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/clients"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите клиента"
          searchPlaceholder="Поиск клиента..."
          noItemsFoundText="Клиенты не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.client ? [props.client] : [],
    },
    {
      key: 'supplier',
      label: 'Поставщик',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/suppliers"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите поставщика"
          searchPlaceholder="Поиск поставщика..."
          noItemsFoundText="Поставщики не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.supplier ? [props.supplier] : [],
    },
    {
      key: 'consultant',
      label: 'Консультант', 
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/admin-api/employees"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите сотрудника"
          searchPlaceholder="Поиск сотрудника..."
          noItemsFoundText="Сотрудники не найдены"
          filterString="group=[5];"
          isMultiSelect={true}
        />
      ),
      initialValue: props.consultant ? [props.consultant] : [],
    },
    {
      key: 'from_store',
      label: 'Из магазина',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/admin-api/stores"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите магазин"
          searchPlaceholder="Поиск магазина..."
          noItemsFoundText="Магазины не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
    {
      key: 'to_store',
      label: 'В магазин',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/admin-api/stores"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите магазин"
          searchPlaceholder="Поиск магазина..."
          noItemsFoundText="Магазины не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
    {
      key: 'date__gte',
      label: 'Дата (с)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'date__lt',
      label: 'Дата (по)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'is_kkm',
      label: 'ККМ',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="true">Да</option>
          <option value="false">Нет</option>
        </select>
      ),
      initialValue: '',
    },
    {
      key: 'style',
      label: 'Тип',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="sales">Продажа</option>
          <option value="return_sales">Возврат продажи</option>
          <option value="movements">Перемещение</option>
          <option value="purchases">Закупка</option>
          <option value="return_purchases">Возврат закупки</option>
          <option value="changes">Изменения</option>
        </select>
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'number', label: 'Номер' },
    { key: 'style', label: 'Тип' },
    { key: 'from_store', label: 'Из магазина', component: (item) => <>{item.from_store?.meta.name}</> },
    { key: 'to_store', label: 'В магазин', component: (item) => <>{item.to_store?.meta.name}</> },
    { key: 'client', label: 'Клиент', component: (item) => <>{item.client?.meta.name}</> },
    { key: 'supplier', label: 'Поставщик', component: (item) => <>{item.supplier?.meta.name}</> },
    { key: 'quantity', label: 'Количество' },
    { key: 'sum', label: 'Сумма' },
    { key: 'profit', label: 'Прибыль' },
    { key: 'date', label: 'Дата', component: (item) => <>{formatDate(item.date)}</> },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/docs/admin-api/docs"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={DocItem}
    />
  );
};

export default DocsList;
