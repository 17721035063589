import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CustomBarChart = ({ data, config }) => {
  const [visibleStats, setVisibleStats] = useState({});
  console.log('CustomBarChart', data, config);
  useEffect(() => {
    if (config?.dataKeys?.length) {  
      const initialState = config.dataKeys.reduce((acc, key) => {
        acc[key.toString()] = true; 
        return acc;
      }, {});
      setVisibleStats(initialState);
    }
  }, [config]);

  // Toggle visibility of a bar
  const toggleStat = (stat) => {
    setVisibleStats((prev) => ({ ...prev, [stat]: !prev[stat] }));
  };

  // Custom Legend with clickable labels
  const renderLegend = (props) => {
    // Ensure all legend items are always rendered
    const fullPayload = config.dataKeys.map((key, index) => ({
      dataKey: key,
      value: config.labels[index],
      color: config.colors[index],
      type: 'rect', // Use rect type for bar charts
    }));

    return (
      <div className="flex justify-center gap-4 mt-4">
        {fullPayload.map((entry, index) => (
          <span
            key={`legend-${index}`}
            onClick={() => toggleStat(entry.dataKey)}
            className="cursor-pointer"
            style={{
              color: visibleStats[entry.dataKey] ? entry.color : '#ccc',
              opacity: visibleStats[entry.dataKey] ? 1 : 0.5,
              textDecoration: visibleStats[entry.dataKey] ? 'none' : 'line-through',
            }}
          >
            {entry.value}
          </span>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend content={renderLegend} />
        {config.dataKeys.map((key, index) =>
          visibleStats[key] ? (
            <Bar
              key={key}
              dataKey={key}
              name={config.labels[index]}
              fill={config.colors[index]}
              radius={[4, 4, 0, 0]} // Slightly rounded tops
            />
          ) : null
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;