import { createApiService } from "./api";
import { BASE_URL } from "data/config";
import { StoreService, EmployeeService, EmployeeGroupService } from "./company";
import { ProjectGroupService, ProjectService, TaskService } from "./projects";
import { OrderService } from "./crm";

// company.js
export const storeService = new StoreService();
export const employeeService = new EmployeeService();
export const employeeGroupService = new EmployeeGroupService();
export const projectGroupService = new ProjectGroupService();
export const projectService = new ProjectService();
export const taskService = new TaskService();

// crm.js
export const orderService = new OrderService();


export async function loadList(endpoint, queryString = "", token = "token") {
  const url = BASE_URL + endpoint + queryString;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorMessage  = `Error: ${endpoint} with status ${response.status} (${response.statusText})`;
    try {
      const errorData = await response.json(); 
      if (errorData.message) {
        errorMessage = errorData.message;
      }
    } catch (parseError) {
      console.error("Failed to parse error response:", parseError);
    }
    throw new Error(errorMessage);

  }

  const data = await response.json();
  return data.response;
}

export async function loadItem(endpoint, id, token = 'token') {
  const url = BASE_URL + endpoint + id + "/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to load data from ${endpoint} with status ${response.status} error: ${response.statusText}`);
  }

  const data = await response.json();
  return data.response;
}
export async function exportList(endpoint, queryString = "", token = "token") {
  console.log("exportList", endpoint, queryString, token);
  const url = BASE_URL + endpoint + queryString;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorMessage = `Failed to load data from ${endpoint} with status ${response.status} error: ${response.statusText}`;
    try {
      const errorData = await response.json();
      if (errorData.message) {
        errorMessage = errorData.message;
      }
    } catch (parseError) {
      console.error("Failed to parse error response:", parseError);
    }
    throw new Error(errorMessage);
  }

  const blob = await response.blob(); // Get the binary data as a Blob
  const downloadUrl = window.URL.createObjectURL(blob); // Create a download link
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = "export.xlsx"; // Default file name
  document.body.appendChild(a);
  a.click(); // Trigger the download
  a.remove();
  window.URL.revokeObjectURL(downloadUrl);
}







