// contexts/LayoutContext.jsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const LayoutContext = createContext({
  Modals: [],
  openModal: () => {},
  closeModal: () => {},
  sidebarContent: null,
  setSidebarContent: () => {}
});

export const LayoutProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [sidebarContent, setSidebarContent] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    if (sidebarContent) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  }, [sidebarContent]);

  const openModal = useCallback((modal) => {
    const newModal = { 
      ...modal, 
      id: `modal-${Date.now()}-${Math.random().toString(36).substr(2, 9)}` 
    };
    setModals(prev => [...prev, newModal]);
  }, []);

  const closeModal = useCallback(() => {
    setModals(prev => prev.slice(0, prev.length - 1));
  }, []);

  return (
    <LayoutContext.Provider value={{ 
      modals, 
      openModal, 
      closeModal,

      sidebarContent,
      setSidebarContent,
      sidebarVisible,
      setSidebarVisible,
    }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};