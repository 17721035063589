import React from 'react';
import { ItemSelector, List, NotificationItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const NotificationsList = (props) => {
  const filterOptions = [
    {
      key: 'title',
      label: 'Заголовок',
      field: (props) => (
        <input 
          type="text" 
          placeholder="Заголовок" 
          value={props.value} 
          className={props.className} 
          onChange={(e) => props.onChange(e.target.value)} 
        />
      ),
      initialValue: '',
    },
    {
      key: 'client',
      label: 'Клиенты',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/clients"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите клиента"
          searchPlaceholder="Поиск клиента..."
          noItemsFoundText="Клиенты не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.client ? [props.client] : [],
    },
    {
      key: 'template',
      label: 'Шаблоны',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/crm/admin-api/notification-templates"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите шаблон"
          searchPlaceholder="Поиск шаблона..."
          noItemsFoundText="Шаблоны не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.template ? [props.template] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'title', label: 'Заголовок' },
    { key: 'description', label: 'Описание' },
    { key: 'tag', label: 'Тег', component: (item) => (
        <span style={{
          backgroundColor: `rgb(${item.tag.red}, ${item.tag.green}, ${item.tag.blue})`,
          padding: '2px 4px',
          borderRadius: '4px',
          color: '#fff',
        }}>
          {item.tag.text}
        </span>
      )
    },
    { key: 'client', label: 'Клиент', component: (item) => <>{item.client.meta.name}</> },
    { key: 'template', label: 'Шаблон', component: (item) => <>{item.template.meta.name}</> },
    { key: 'sent_at', label: 'Отправлено', component: (item) => <>{formatDate(item.sent_at)}</> },
    { key: 'read_at', label: 'Прочитано', component: (item) => <>{formatDate(item.read_at)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/crm/admin-api/notifications/list"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={NotificationItem}
    />
  );
};

export default NotificationsList;