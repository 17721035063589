import React, { useState, useEffect } from 'react';
import { TaskForm } from 'components';
import TaskItem from '../../components/Projects/TaskItem';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from '../../contexts/ContextProvider';
import { PlusCircle, Calendar, User, CheckCircle } from 'lucide-react';
import { projectService, taskService } from 'services';

const ProjectItem = ({ project}) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { openModal, closeModal } = useLayout();
  const { showToast, token } = useStateContext();
  
  const fetchTasks = async () => {
    try {
      const projectTasks = await projectService.getTasks(project.id, '', '', token);
      setTasks(projectTasks.response.rows || []);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTask = () => {
    openModal({
      component: <TaskForm projectId={project.id} refreshList={fetchTasks} />,
      title: 'Add Task',
      type: 'right',
      confirmCloseText: 'Are you sure you want to close? All unsaved changes will be lost.',
    });
  };


  useEffect(() => {
    fetchTasks();
  }, [project.id]);

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const response = await taskService.updateStatus(taskId, newStatus, token);

      if (response.status === 1) {
        showToast({
          title: 'Error',
          content: response.message || 'Error updating task status',
          type: 'error',
        });
        return;
      }
      showToast({
        title: 'Success',
        content: 'Task status updated successfully',
        type: 'success',
      });


      setTasks(tasks.map(task => 
        task.id === taskId ? { ...task, status: newStatus } : task
      ));
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-4 h-[calc(100vh-4rem)]">
        <div className="animate-pulse">
          <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="space-y-3">
            <div className="h-20 bg-gray-100 rounded"></div>
            <div className="h-20 bg-gray-100 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md flex flex-col h-[calc(100vh-16rem)]">
      <div className="p-3 border-b flex-none">
        <h2 className="text-lg font-semibold truncate">{project.name}</h2>
        <p className="text-sm text-gray-600 mt-1 line-clamp-2">{project.text}</p>
      </div>

      <div 
        className="flex-1 overflow-y-auto min-h-0 p-3 space-y-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400"
        style={{ scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f3f4f6' }}
      >
        {tasks.map(task => (
          <TaskCard 
            key={task.id} 
            task={task} 
            onStatusChange={handleStatusChange}
            refreshList={fetchTasks}
          />
        ))}
      </div>

      <div className="p-3 border-t flex-none mt-auto">
        <button 
          className="w-full flex items-center justify-center gap-2 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
          onClick={handleAddTask}
        >
          <PlusCircle className="w-4 h-4" />
          Add Task
        </button>
      </div>
    </div>
  );
};

// Task Card component remains the same
const TaskCard = ({ task, onStatusChange, refreshList }) => {
  const isDone = task.status === 'done';
  const { openModal } = useLayout();
  
  const handleSelect = () => {
    openModal({
      component: <TaskItem task={task} refreshList={refreshList}/>,
      title: task.name,
    });
  };

  return (
    <div className={`p-3 rounded-lg border cursor-pointer ${isDone ? 'bg-green-50 border-green-200' : 'bg-white border-gray-200'}`} onClick={handleSelect}>
      <div className="flex items-start gap-3">
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onStatusChange(task.id, isDone ? 'todo' : 'done');
          }}
          className={`mt-1 ${isDone ? 'text-green-500' : 'text-gray-400'}`}
        >
          <CheckCircle className="w-5 h-5" />
        </button>
        
        <div className="flex-1">
          <div className="flex items-start justify-between">
            <h4 className={`font-medium ${isDone ? 'text-green-700' : 'text-gray-900'}`}>
              {task.name}
            </h4>
          </div>
          
          <p className={`mt-1 text-sm ${isDone ? 'text-green-600' : 'text-gray-600'}`}>
            {task.text}
          </p>
          
          <div className="flex items-center gap-4 mt-2 text-xs text-gray-500">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {new Date(task.deadline).toLocaleDateString()}
            </div>
            <div className="flex items-center">
              <User className="w-4 h-4 mr-1" />
              {task.author.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;