import React, { useState, useEffect,Suspense } from 'react';
import { Line } from 'react-chartjs-2';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { BASE_URL } from 'data/config';
import {Modal, DocItem, DateRangePicker } from 'components';
import { useStateContext } from 'contexts/ContextProvider';
import { startOfWeek, startOfMonth, endOfWeek, endOfMonth, set } from 'date-fns';
import ConsultantSales from './ConsultantSales';


ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const formatDate = (date) => date.toISOString().split('T')[0];

const ConsultantDashboard = () => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [consultantData, setConsultantData] = useState(null);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, Modal, setModal } = useStateContext();
  const [selectedSale, setSelectedSale] = useState(null);

  const onSaleSelect = (sale) => {
    console.log('Selected sale:', sale);
    setSelectedSale(sale);
    setModal(true);
  };

  // Fetch consultant stats and docs
  const fetchConsultantData = async (startDate, endDate) => {
    setLoading(true);
    setError(null);
    try {
      // Fetch consultant stats
      const statsResponse = await fetch(
        `${BASE_URL}/admin-api/consultant-stats`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            'from_date': startDate,
            'to_date': endDate,
          }),
        }
      );

      if (!statsResponse.ok) {
        throw new Error('Failed to fetch consultant stats');
      }

      const statsData = await statsResponse.json();
      if (statsData.status === 0) {
        setConsultantData(statsData.response.consultant.first_name || []);
        setStats(statsData.response.stats || []);
      } else {
        throw new Error(statsData.message || 'An error occurred');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onDateRangeApply = ({ startDate, endDate }) => {
    setDateRange([startDate, endDate]);
  };


  useEffect(() => {
    const [startDate, endDate] = dateRange;
    fetchConsultantData(formatDate(startDate), formatDate(endDate));
  }, [dateRange]);


  const financialChartData = {
    labels: stats.map((item) => item.date),
    datasets: [
      {
        label: 'Себестоимость',
        data: stats.map((item) => item.day_purchase),
        borderColor: '#ff6384',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: false, 
        tension: 0.4,
      },
      {
        label: 'Сумма',
        data: stats.map((item) => item.day_sum),
        borderColor: '#36a2eb',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: false, 
        tension: 0.4,
      },
      {
        label: 'Прибыль',
        data: stats.map((item) => item.day_profit),
        borderColor: '#4bc0c0',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: false, 
        tension: 0.4,
      }
    ].filter((dataset) => dataset.data.length > 0),
  };

  const countQuantityChartData = {
    labels: stats.map((item) => item.date),
    datasets: [
      {
        label: 'Кол-во продаж',
        data: stats.map((item) => item.day_count),
        borderColor: '#ffc658',
        backgroundColor: 'rgba(255, 198, 88, 0.5)',
        fill: false, 
        tension: 0.4,
      },
      {
        label: 'Кол-во товаров',
        data: stats.map((item) => item.day_quantity),
        borderColor: '#ff9f40',
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
        fill: false, 
        tension: 0.4,
      },
    ].filter((dataset) => dataset.data.length > 0),
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4 lg:p-8">

      <h1 className="text-2xl font-bold mb-8 text-gray-800 dark:text-white">
        Показатели сотрудника: {consultantData ? consultantData.username  : 'Unknown'}
      </h1>

      <DateRangePicker onApply={onDateRangeApply} />
      
      {/* Error display */}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {/* Line Chart for Financial Data */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-8">
        <h2 className="text-lg font-bold mb-4">Финансовые показатели</h2>
        <Line data={financialChartData} options={{ responsive: true, plugins: { legend: { display: true }, tooltip: {mode: 'index', intersect: false} } }} />
      </div>

      {/* Bar Chart for Count and Quantity */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-8">
        <h2 className="text-lg font-bold mb-4">Количество</h2>
        <Line data={countQuantityChartData} options={{ responsive: true, plugins: { legend: { display: true } } }} />
      </div>

      {/* Sales Table for Consultant Docs */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-8">
        <h2 className="text-lg font-bold mb-4">Consultant Documents</h2>
        <ConsultantSales startDate={formatDate(dateRange[0])} endDate={formatDate(dateRange[1])} token={token} onChoose={onSaleSelect} />
      </div>

      {/* Right Modal for Document Details */}
      {Modal && selectedSale && (
        <Modal title={"Документ: " + selectedSale.number} afterClose={() => setSelectedSale(null)}>
          <Suspense fallback={<p style={{ textAlign: 'center' }}>Загрузка...</p>}>
            <DocItem doc={selectedSale} />
          </Suspense>
          <h1 className="flex md:flex-row flex-col">Here will be related statistics and more info...</h1>
        </Modal>
      )}
    </div>
    
  );
};

export default ConsultantDashboard;
