import React from 'react'
import { ClientsList, Tabs, Tab } from 'components'

const ClientGroupItem = ({item}) => {
  return (
    <div>
      <Tabs initialTab="clients">
        <Tab key="clients" label="Клиенты">
          <ClientsList clientgroup={item} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ClientGroupItem