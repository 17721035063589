import React from 'react';
import { List, SupplierItem, ItemSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const SuppliersList = (props) => {
  const filterOptions = [
    {
      key: 'created__gte',
      label: 'Создано (С)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lt',
      label: 'Создано (По)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Обновлено (С)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lt',
      label: 'Обновлено (По)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'suppliergroup',
      label: 'Группа',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/parties/admin-api/supplier-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите группу клиентов"
          searchPlaceholder="Поиск группы клиентов..."
          noItemsFoundText="Группы клиентов не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.suppliergroup ? [props.suppliergroup] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Имя' },
    { key: 'phone', label: 'Телефон' },
    { key: 'email', label: 'Электронная почта', component: (item) => <>{item.email || '—'}</> },
    {
      key: 'product__count',
      label: 'Продукты',
      component: (item) => (
        <>{item.products?.meta.size}</>
      ),
    },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || '/parties/admin-api/suppliers/'}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={SupplierItem}
    />
  );
};

export default SuppliersList;