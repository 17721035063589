import { BaseApiService, apiClient } from './api';

export class ProjectGroupService extends BaseApiService {
    constructor() {
        super('/admin-api/project-groups');
    }

    // Get project groups
    getProjects(projectGroupId, queryString='', token = 'token') {
        return apiClient.get(`${this.endpoint}/${projectGroupId}/projects`, queryString, token);
    }

}

export class ProjectService extends BaseApiService {
    constructor() {
        super('/admin-api/projects');
    }

    // Get members of a project
    getMembers(projectId, queryString = '', token = 'token') {
        return apiClient.get(`${this.endpoint}/${projectId}/members`, queryString, token);
    }

    // Update project members
    updateMembers(projectId, members, token = 'token') {
        const data = members.map(member => ({ id: member.id }));
        return apiClient.put(`${this.endpoint}/${projectId}/members/`, data, token);
    }

    // Add member to project
    addMember(projectId, memberId, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${projectId}/members/`, { id: memberId }, token);
    }

    // Remove member from project
    removeMember(projectId, memberId, token = 'token') {
        return apiClient.delete(`${this.endpoint}/${projectId}/members/`, { id: memberId }, token);
    }

    // Get tasks of a project
    getTasks(projectId, status = '', queryString = '', token = 'token') {
        const query = status ? 
            (queryString ? `${queryString}&status=${status}` : `?status=${status}`) : 
            queryString;
        return apiClient.get(`${this.endpoint}/${projectId}/tasks`, query, token);
    }

    // Add task to project
    addTask(projectId, taskData, token = 'token') {
        return apiClient.post(`${this.endpoint}/${projectId}/tasks`, taskData, token);
    }
}

export class TaskService extends BaseApiService {
    constructor() {
        super('/admin-api/tasks');
    }

    // Update task status
    updateStatus(taskId, status, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/`, { status }, token);
    }

    // Get task notes
    getNotes(taskId, queryString = '', token = 'token') {
        return apiClient.get(`${this.endpoint}/${taskId}/task-notes`, queryString, token);
    }

    // Add task note
    addNote(taskId, text, token = 'token') {
        return apiClient.post(`${this.endpoint}/${taskId}/task-notes/`, { text }, token);
    }

    // Update task note
    updateNote(taskId, noteId, text, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/task-notes/${noteId}/`, { text }, token);
    }

    // Delete task note
    deleteNote(taskId, noteId, token = 'token') {
        return apiClient.delete(`${this.endpoint}/${taskId}/task-notes/${noteId}/`, {}, token);
    }

    // Archive task
    archive(taskId, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/`, { is_archived: true }, token);
    }

    // Update task deadline
    updateDeadline(taskId, deadline, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/`, { deadline }, token);
    }

    // Update task text
    updateText(taskId, text, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/`, { text }, token);
    }

    // Update task name
    updateName(taskId, name, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${taskId}/`, { name }, token);
    }
}
