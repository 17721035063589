import React, { useState } from 'react'
import { EmployeesList, Tabs, Tab } from 'components'

const EmployeeGroupItem = ({item}) => {
  const [activeTab, setActiveTab] = useState('goods');

  return (
    <div>

      <Tabs initialTab="employees">
        <Tab key="employees" label="Сотрудники">
          <EmployeesList filterString={'group=' + item.id} />
        </Tab>
        <Tab key="sections" label="Доступные разделы">
          <div className="mb-4">
            {/* Map through sections to create checkboxes */}
            {item.sections && item.sections.map((section) => (
              <div key={section.id} onClick={()=>handleCheckboxChange(section.id)} className={(section.included ? "bg-gray-200 "  : "bg-white  ") + "p-4 mb-2 shadow-md rounded-lg hover:bg-gray-300"}>
                <label className="flex items-center text-gray-800 text-md font-semibold mb-2 cursor-pointer" >
                  <input
                    type="checkbox"
                    checked={section.included}
                    onChange={() => handleCheckboxChange(section.id)}
                    className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                  />
                  {section.name}
                </label>
                <div className="ml-6">
                  {/* Display sub-sections */}
                  {section.sub_sections.map((subSection) => (
                    <p key={subSection.url} className="text-gray-600 text-sm my-1 transition-colors duration-200">
                      {subSection.name}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
      
    </div>
  )
}

export default EmployeeGroupItem