import React from 'react';
import { List, ProductItem, ItemSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const ProductsList = (props) => {
  const filterOptions = [
    {
      key: 'supplier',
      label: 'Поставщик',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/suppliers"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите поставщика"
          searchPlaceholder="Поиск поставщика..."
          noItemsFoundText="Поставщики не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.supplier ? [props.supplier] : [],
    },
    {
      key: 'categories',
      label: 'Категория',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/products/admin-api/categories"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите категорию товаров"
          searchPlaceholder="Поиск категории товаров..."
          noItemsFoundText="Категории товаров не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.category ? [props.category] : [],
    },
    {
      key: 'productgroup',
      label: 'Группа товаров',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/products/admin-api/product-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите группу товаров"
          searchPlaceholder="Поиск группы товаров..."
          noItemsFoundText="Группы товаров не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.productGroup ? [props.productGroup] : [],
    },
    {
      key: 'price__gte',
      label: 'Цена (Мин)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          value={value}
          placeholder="Мин. цена"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'price__lte',
      label: 'Цена (Макс)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          value={value}
          placeholder="Макс. цена"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__gte',
      label: 'Создано после',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Создано до',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Название' },
    { key: 'barcode', label: 'Штрихкод' },
    { key: 'code', label: 'Код' },
    { key: 'price', label: 'Цена' },
    { key: 'cost', label: 'Себестоимость' },
    { key: 'supplier', label: 'Поставщик', component: (item) => <>{item.supplier?.name}</> },
    { key: 'discount', label: 'Скидка' },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/products/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ProductItem}
    />
  );
};

export default ProductsList;
