import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar, ThemeSettings } from '../components/index';
import { FiSettings } from 'react-icons/fi';
import { useStateContext } from "../contexts/ContextProvider";
import { useLayout } from '../contexts/LayoutContext';
import { RightModal, CenterModal, Tooltip } from '../components';
import AuthenticationPage from './Authentication';
import ErrorPage from './Error';

const ModalContainer = () => {
  const { modals } = useLayout();

  return (
    <>
      {modals.map((modal, index) => {
        if(modal.type === 'right') {
          return <RightModal 
            key={modal.id}
            title={modal.title || `Modal ${index + 1}`}
            confirmCloseText={modal.confirmCloseText}
          >
            {modal.component}
          </RightModal> 
        } 
        else{
          return <CenterModal 
            key={modal.id}
            title={modal.title || `Modal ${index + 1}`}
            confirmCloseText={modal.confirmCloseText}
          >
            {modal.component}
          </CenterModal>  
        }
      })}
    </>
  );
};


// const CenterModalContainer = () => {
//   const { centerModals, closeCenterModal } = useLayout();

//   return (
//     <>
//       {centerModals.map((modal, index) => (
//         <CenterModal 
//           key={modal.id}
//           title={modal.title || `Modal ${index + 1}`}
//           afterClose={() => closeCenterModal(modal.id)}
//         >
//           {modal.component}
//         </CenterModal>
//       ))}
//     </>
//   );
// };

const RootLayout = () => {
  const { currentMode, currentColor, themeSettings, setThemeSettings, token, user } = useStateContext();
  const { sidebarContent, setSidebarVisible, sidebarVisible } = useLayout();
  const location = useLocation();

  if(token === null) return <AuthenticationPage/>;

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <Navbar />

      <div className="flex flex-col relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <Tooltip content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              style={{ background: currentColor, borderRadius: "50%" }}
            >
              <FiSettings />
            </button>
          </Tooltip>
        </div>

        <div className="bg-main-bg dark:bg-main-dark-bg w-full min-h-screen">
          <ModalContainer />
          {themeSettings && <ThemeSettings/>}
          <div className="mt-32">
            <div className="pt-10 bg-white dark:bg-gray-700 rounded-3xl">
              <div className="flex w-full">
                {/* Left Sidebar */}
                {sidebarVisible && (
                  <div className="w-1/5 px-4">
                    {sidebarContent}
                  </div>
                )} 
                {sidebarContent && (
                  <button className="w" onClick={()=>setSidebarVisible(!sidebarVisible)}>HIDE</button>
                )}


                {/* Main Content */}
                <div className={`${sidebarVisible ? 'w-4/5' : 'w-full mx-auto max-w-screen-xl'}  bg-white dark:bg-gray-700`}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootLayout;