import React from 'react';

const Checkbox = ({ 
  id, 
  label, 
  checked, 
  onChange,
  disabled = false,
  className = ''
}) => {
  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <div className="relative flex items-center">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
          className={`
            w-4 h-4 
            rounded
            border-gray-300
            text-blue-600 
            cursor-pointer
            focus:ring-2 
            focus:ring-blue-500 
            focus:ring-offset-0
            transition-colors
            disabled:opacity-50
            disabled:cursor-not-allowed
            ${checked ? 'bg-blue-600 border-blue-600' : 'bg-white'}
          `}
        />
        <div className={`
          absolute
          inset-0
          rounded
          pointer-events-none
          transition-opacity
          ${checked ? 'opacity-100' : 'opacity-0'}
        `}>
          <svg
            className="w-4 h-4 text-white"
            viewBox="0 0 16 16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <polyline points="3,8 7,12 13,4" />
          </svg>
        </div>
      </div>
      {label && (
        <label
          htmlFor={id}
          className={`
            text-sm
            select-none
            cursor-pointer
            ${disabled ? 'text-gray-400' : 'text-gray-700'}
          `}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;