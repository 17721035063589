import React, { useMemo } from 'react';
import CustomBarChart from '../../Charts/BarChart';
import CustomPieChart from '../../Charts/PieChart';
import { Card, CardHeader, CardTitle, CardContent, Tabs, Tab } from 'components';

const StoreStats = ({ data }) => {
  // Extract stores from the data
  const stores = useMemo(() => 
    data.map(storeData => ({
      id: storeData.store.id,
      name: storeData.store.name
    }))
  , [data]);

  const formatMetricData = (metricKey) => {
    const formattedData = {};
    
    // Initialize with all dates from all stores
    data.forEach(storeData => {
      storeData.daily_stats.forEach(daily => {
        if (!formattedData[daily.date]) {
          formattedData[daily.date] = { date: daily.date };
        }
      });
    });

    // Fill in store values for each date
    data.forEach(storeData => {
      const storeId = storeData.store.id;
      storeData.daily_stats.forEach(daily => {
        formattedData[daily.date][storeId] = daily[metricKey];
      });
    });

    return Object.values(formattedData).sort((a, b) => a.date.localeCompare(b.date));
  };

  const calculateTotalsByStore = (metricKey) => {
    return data.map(storeData => ({
      name: storeData.store.name,
      value: storeData.total_stats[`total_${metricKey}`] || 0
    }));
  };

  const chartConfigs = {
    orders: {
      title: "Количество продаж",
      subtitle: "Количество продаж по магазинам",
      metric: "day_count",
      totalMetric: "count"
    },
    revenue: {
      title: "Выручка",
      subtitle: "Общая сумма продаж по магазинам (сом)",
      metric: "day_sum",
      totalMetric: "sum"
    },
    profit: {
      title: "Прибыль",
      subtitle: "Чистая прибыль по магазинам (сом)",
      metric: "day_profit",
      totalMetric: "profit"
    },
    costs: {
      title: "Себестоимость",
      subtitle: "Себестоимость по магазинам (сом)",
      metric: "day_purchase",
      totalMetric: "purchase"
    },
    discounts: {
      title: "Скидки",
      subtitle: "Общая сумма предоставленных скидок по магазинам (сом)",
      metric: "day_discount_sum",
      totalMetric: "discount_sum"
    },
    bonuses: {
      title: "Бонусный кэшбэк",
      subtitle: "Общая сумма начисленного бонусного кэшбэка (сом)",
      metric: "day_bonus_cashback",
      totalMetric: "bonus_cashback"
    },
    bonusSpent: {
      title: "Потраченные бонусы",
      subtitle: "Общая сумма потраченных бонусов (сом)",
      metric: "day_bonus_spent",
      totalMetric: "bonus_spent"
    }
  };

  const chartsData = useMemo(() => {
    const formattedData = {};
    Object.entries(chartConfigs).forEach(([key, config]) => {
      formattedData[key] = {
        barData: formatMetricData(config.metric),
        pieData: calculateTotalsByStore(config.totalMetric)
      };
    });
    return formattedData;
  }, [data]);

  const barchartConfig = {
    dataKeys: stores.map(store => store.id),
    labels: stores.map(store => store.name),
    colors: ['#8884d8', '#82ca9d', '#ffc658', '#a4de6c', '#ff8042', 
             '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c', '#ff8042']
  };

  const renderMetricTab = (key, config) => {
    return (
      <div className="py-4">
        <div className="grid grid-cols-1 gap-6">
          <Card className="w-full">
            <CardHeader>
              <CardTitle>{config.title} - Ежедневная тенденция</CardTitle>
              <p className="text-sm text-gray-600">{config.subtitle}</p>
            </CardHeader>
            <CardContent>
              <div className="h-96">
                <CustomBarChart 
                  data={chartsData[key].barData}
                  config={barchartConfig}
                />
              </div>
            </CardContent>
          </Card>
          
          <Card className="w-full">
            <CardHeader>
              <CardTitle>{config.title} - Распределение по магазинам</CardTitle>
              <p className="text-sm text-gray-600">Общее распределение по магазинам</p>
            </CardHeader>
            <CardContent>
              <div className="h-96">
                <CustomPieChart 
                  data={chartsData[key].pieData}
                  config={{colors: barchartConfig.colors}}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <Tabs initialTab="orders">
        {Object.entries(chartConfigs).map(([key, config]) => (
          <Tab key={key} label={config.title}>
            {renderMetricTab(key, config)}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default StoreStats;