import React from 'react'
import { SuppliersList, Tabs, Tab } from 'components'

const SupplierGroupItem = ({item}) => {
  return (
    <div>
      <Tabs initialTab="suppliers">
        <Tab key="suppliers" label="Поставщики">
          <SuppliersList suppliergroup={item} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default SupplierGroupItem