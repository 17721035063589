import React, { useState, useEffect, Suspense } from 'react';
import { ChevronLeft, ChevronRight, ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';
import { useStateContext } from 'contexts/ContextProvider';
import { loadList } from 'services';

const PositionsList = ({ filterString }) => {
  const [positions, setPositions] = useState([]);
  const [orderString, setOrderString] = useState('');
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const { showToast, token } = useStateContext();

  const fetchPositions = async () => {
    try {
      setLoading(true);
      const queryParams = [
        filterString && `filter=${filterString}`,
        orderString && `order=${orderString}`,
        `offset=${offset}`,
        `limit=${limit}`
      ].filter(Boolean);
        
      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
      const response = await loadList("/docs/admin-api/positions", queryString, token);
      setPositions(response.rows);
      setTotalCount(response.meta.size);
    } catch (error) {
      showToast({
        title: 'Ошибка при загрузке сотрудников',
        content: error.message,
        cssClass: 'e-toast-danger',
        icon: 'e-error toast-icons'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, [filterString, orderString, offset, limit]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'desc'
  });

  const handleSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
    setOrderString(`${key},${direction}`);  
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <ArrowUpDown className="w-3 h-3 ml-1 inline-block" />;
    }
    return sortConfig.direction === 'desc' 
      ? <ArrowDown className="w-3 h-3 ml-1 inline-block" />
      : <ArrowUp className="w-3 h-3 ml-1 inline-block" />;
  };

  const formatDate = (date) => new Date(date).toLocaleDateString();

  const totalPages = Math.ceil(totalCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const handlePageChange = (newPage) => {
    setOffset((newPage - 1) * limit);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    setOffset(0);
  };

  return (
    <div className="space-y-4">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full">
          <div className="shadow-lg overflow-hidden border-b border-gray-400 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: 'product', label: 'Наименование' },
                    { key: 'quantity', label: 'Количество' },
                    { key: 'price', label: 'Цена' },
                    { key: 'discount_sum', label: 'Скидка' },
                    { key: 'sum', label: 'Итог' }
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      scope="col"
                      className="px-2 py-2 text-left text-xs font-normal text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSort(key)}
                    >
                      <span className="flex items-center">
                        {label}
                        {getSortIcon(key)}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={`bg-white divide-y divide-gray-200 ${loading ? 'opacity-50' : ''}`}>
                {positions.map((position) => (
                  <tr 
                    key={position.id} 
                    className="hover:bg-gray-50 cursor-pointer transition-colors"
                  >
                    <td className="px-2 py-2 whitespace-normal text-xs text-blue-600">
                      {position.product.meta.name}
                    </td>
                    <td className="px-2 py-2 text-xs text-right text-gray-500">
                      {position.quantity}
                    </td>
                    <td className="px-2 py-2 text-xs text-right text-gray-500">
                      {position.price}
                    </td>
                    <td className="px-2 py-2 text-xs text-right text-gray-500">
                      {position.discount_sum || '0.00'}
                    </td>
                    <td className="px-2 py-2 text-xs text-right text-gray-500">
                      {position.sum}
                    </td>
                  </tr>
                ))}
                <tr className="bg-gray-50">
                  <td className="px-2 py-2 text-xs font-medium">
                    Итог
                  </td>
                  <td className="px-2 py-2 text-xs text-right font-medium">
                    {positions.reduce((sum, pos) => sum + pos.quantity, 0)}
                  </td>
                  <td className="px-2 py-2 text-xs text-right font-medium">
                    -
                  </td>
                  <td className="px-2 py-2 text-xs text-right font-medium">
                    {positions.reduce((sum, pos) => sum + (pos.discount_sum || 0), 0).toFixed(2)}
                  </td>
                  <td className="px-2 py-2 text-xs text-right font-medium">
                    {positions.reduce((sum, pos) => sum + pos.sum, 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-25">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between px-4">
        <div className="flex items-center space-x-2 text-xs text-gray-700">
          <span>Показать:</span>
          <select
            value={limit}
            onChange={handleLimitChange}
            className="border border-gray-300 rounded px-2 py-1 text-xs"
            disabled={loading}
          >
            {[10, 20, 50, 100].map(value => (
              <option key={value} value={value}>{value}</option>
            ))}
          </select>
          <span>из {totalCount} записей</span>
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || loading}
            className="p-1 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          
          <div className="flex items-center space-x-1">
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              let pageNum;
              if (totalPages <= 5) {
                pageNum = i + 1;
              } else if (currentPage <= 3) {
                pageNum = i + 1;
              } else if (currentPage >= totalPages - 2) {
                pageNum = totalPages - 4 + i;
              } else {
                pageNum = currentPage - 2 + i;
              }
              
              return (
                <button
                  key={pageNum}
                  onClick={() => handlePageChange(pageNum)}
                  disabled={loading}
                  className={`px-2 py-1 rounded text-xs ${
                    currentPage === pageNum
                      ? 'bg-gray-900 text-white'
                      : 'hover:bg-gray-100'
                  } ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {pageNum}
                </button>
              );
            })}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || loading}
            className="p-1 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PositionsList;