import React, { useState, Suspense } from "react";
import { ClientGroupsList, Loading, Tooltip, ClientGroupForm } from "components";
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from "contexts/ContextProvider";
import { Info } from 'lucide-react';

const ClientGroups = () => {
  const { currentColor } = useStateContext();
  const { openModal } = useLayout();

  const handleAddClientGroup = () => {
    openModal({
      component: <ClientGroupForm />,
      title: "Добавить группу клиентов",
    });
  };

  return (
    <>
      <Suspense fallback={<Loading/>}>
        <div className="bg-gray-100 py-4 px-6 flex justify-between items-center relative">
          <div className="flex items-center relative">
            <Tooltip trigger='click' className="mr-2">
              <button className="text-gray-700 hover:text-gray-900 mr-2 mt-1">
                <Info size={20} />
              </button>
            </Tooltip>
            <h1 className="text-2xl font-bold">Группы клиентов</h1>
          </div>
          <div className="flex items-center">
            <button className="text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleAddClientGroup()}>
              + Группа клиентов
            </button>
          </div>
        </div>
        
        <ClientGroupsList/>
      </Suspense>
    </>
  );
};

export default ClientGroups;
