import React, { useEffect, useState } from 'react';
import { useStateContext } from 'contexts/ContextProvider';
import { ItemSelector } from 'components';
import { loadList } from 'services';
import { storeService } from 'services';

const Consultants = () => {
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState({});
    const { token, showToast } = useStateContext();

    const fetchStores = async () => {
        try {
            setLoading(true);
            const response = await loadList('/admin-api/stores');
            setStores(response.rows);
        } catch (error) {
            console.error('Error fetching stores:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStores();
    }, []);

    const handleAddEmployee = async (store, employee) => {
        try {
            setUpdating(prev => ({ ...prev, [store.id]: true }));
            
            await storeService.addEmployee(store.id, employee.id, token);
            
            showToast({
                title: 'Успешно!',
                content: `Сотрудник ${employee.name} добавлен в магазин ${store.name}`,
                type: 'success',
            });
            // Update local state
            setStores(prevStores => prevStores.map(s => {
                if (s.id === store.id) {
                    return {
                        ...s,
                        consultants: {
                            ...s.consultants,
                            rows: [...s.consultants.rows, employee]
                        }
                    };
                }
                return s;
            }));
        } catch (error) {
            console.error('Error adding employee:', error);
        } finally {
            setUpdating(prev => ({ ...prev, [store.id]: false }));
        }
    };

    const handleRemoveEmployee = async (store, employeeId) => {
        try {
            setUpdating(prev => ({ ...prev, [store.id]: true }));
            
            await storeService.removeEmployee(store.id, employeeId, token);
            
            showToast({
                title: 'Успешно!',
                content: `Сотрудник удален из магазина ${store.name}`,
                type: 'success',
            });
            setStores(prevStores => prevStores.map(s => {
                if (s.id === store.id) {
                    return {
                        ...s,
                        consultants: {
                            ...s.consultants,
                            rows: s.consultants.rows.filter(item => item.id !== employeeId)
                        }
                    };
                }
                return s;
            }));
        } catch (error) {
            console.error('Error removing employee:', error);
        } finally {
            setUpdating(prev => ({ ...prev, [store.id]: false }));
        }
    };

    return (
        <div className="container mx-auto p-4">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {stores.map((store) => (
                        <div key={store.id} className="border rounded-lg p-4 shadow-md bg-white">
                            <h2 className="text-lg font-semibold mb-2">{store.name}</h2>
                            <ItemSelector
                                targetUrl="/admin-api/employees"
                                onSelect={(item) => handleAddEmployee(store, item)}
                                onRemove={(id) => handleRemoveEmployee(store, id)}
                                selectedItems={store.consultants.rows}
                                placeholder="Выберите сотрудника"
                                searchPlaceholder="Поиск сотрудника..."
                                noItemsFoundText="Сотрудники не найдены"
                                filterString="group=[5];"
                                isMultiSelect={true}
                                disabled={updating[store.id]}
                            />
                            {updating[store.id] && (
                                <div className="mt-2 text-sm text-gray-500">
                                    Обновление...
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Consultants;