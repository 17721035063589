import React, { useState } from 'react';
import { ItemSelector } from 'components';
import { Calendar } from 'lucide-react';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';
import { taskService } from 'services';

const TaskForm = ({ task, projectId, refreshList }) => {
  const { closeModal } = useLayout();
  const { user, showToast } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [taskData, setTaskData] = useState(task || {
    name: '',
    text: '',
    deadline: new Date().toISOString().split('T')[0],
    order: null,
    status: 'todo'
  });
  const [orders, setOrders] = useState(task ? [task?.order] : []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        ...taskData,
        project_id: projectId,
        author_id: user.id,
        order_id: orders[0]?.id,
      };

      const response = await taskService.save(data);
      
      if (response.status === 1) {
        setErrors(response.response || {});
        showToast({
          title: 'Error',
          content: response.message || 'Validation error',
          type: 'error',
        });
        return;
      }

      showToast({
        title: 'Success!',
        content: task ? 'Task updated successfully!' : 'Task created successfully!',
        type: 'success',
      });
      refreshList ? refreshList() : null;
      closeModal();
    } catch (error) {
      showToast({
        title: 'Error!',
        content: error.message || 'Something went wrong!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setTaskData((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const inputClassName = (error) => `
    mt-1 block w-full rounded-md
    ${error 
      ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
      : 'border-blue-500 ring-gray-100 focus:ring-blue-500'
    }
    transition-colors duration-200
    p-2.5
    ring-2 focus:ring-opacity-50 
    outline-none
  `;

  return (
    <div className="p-8 bg-white">
      <h2 className="text-2xl font-semibold mb-8 text-gray-800">
        {task ? 'Edit Task' : 'Create Task'}
      </h2>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Task Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={inputClassName(errors.name)}
            value={taskData.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          {errors.name && (
            <p className="mt-1.5 text-sm text-red-500">{errors.name}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            rows={3}
            className={inputClassName(errors.text)}
            value={taskData.text || ''}
            onChange={(e) => handleInputChange('text', e.target.value)}
          />
          {errors.text && (
            <p className="mt-1.5 text-sm text-red-500">{errors.text}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Deadline <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="date"
              className={`${inputClassName(errors.deadline)} pl-10`}
              value={taskData.deadline || ''}
              onChange={(e) => handleInputChange('deadline', e.target.value)}
            />
          </div>
          {errors.deadline && (
            <p className="mt-1.5 text-sm text-red-500">{errors.deadline}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Order
          </label>
          <ItemSelector
            targetUrl="/crm/admin-api/orders"
            onSelect={(item) => setOrders([item])}
            onRemove={(id) => setOrders([])}
            selectedItems={orders}
            placeholder="Выберите отдел"
            searchPlaceholder="Поиск отделов..."
            noItemsFoundText="Отделы не найдены"
            isMultiSelect={false}
          />
          {errors.order_id && (
            <p className="mt-1.5 text-sm text-red-500">{errors.order_id }</p>
          )}
        </div> 

        {task && (
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Status
            </label>
            <select
              className={inputClassName(errors.status)}
              value={taskData.status || 'todo'}
              onChange={(e) => handleInputChange('status', e.target.value)}
            >
              <option value="todo">To Do</option>
              <option value="done">Done</option>
            </select>
            {errors.status && (
              <p className="mt-1.5 text-sm text-red-500">{errors.status}</p>
            )}
          </div>
        )}

        <div className="flex justify-end space-x-4 pt-4">
          <button
            type="button"
            onClick={closeModal}
            className="px-6 py-2.5 border border-gray-200 rounded-md text-gray-700 hover:bg-gray-50 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSave}
            disabled={loading}
            className={`px-6 py-2.5 rounded-md text-white transition-colors duration-200 ${
              loading 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
            }`}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskForm;