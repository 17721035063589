import { BaseApiService, apiClient } from './api';

export class StoreService extends BaseApiService {
    constructor() {
        super('/admin-api/stores');
    }

    getEmployees(storeId, queryString = '', token = 'token') {
        return apiClient.get(`${this.endpoint}/${storeId}/employees/`, queryString, token);
    }

    addEmployee(storeId, employeeId, token = 'token') {
        return apiClient.patch(`${this.endpoint}/${storeId}/employees/`, { id: employeeId }, token);
    }

    removeEmployee(storeId, employeeId, token = 'token') {
        return apiClient.delete(`${this.endpoint}/${storeId}/employees/`, { id: employeeId }, token);
    }

    updateEmployees(storeId, employees, token = 'token') {
        const data = employees.map(employee => ({ id: employee.id }));
        return apiClient.put(`${this.endpoint}/${storeId}/employees/`, data, token);
    }
}

export class EmployeeService extends BaseApiService {
    constructor() {
        super('/admin-api/employees');
    }
}

export class EmployeeGroupService extends BaseApiService {
    constructor() {
        super('/admin-api/employee-groups');
    }
}
