import React, { useState, useEffect } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { FaPrint } from 'react-icons/fa';
import { loadList } from 'services';
import { useStateContext } from 'contexts/ContextProvider';

const SHIFTS_URL = '/pos/admin-api/shifts';
const DOCS_URL = '/docs/admin-api/docs';
const LIMIT = 10;

const Shifts = () => {
  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { token, showToast } = useStateContext();

  const fetchShifts = async () => {
    try {
      setLoading(true);
      const queryString = `?offset=${offset}&limit=${LIMIT}`;
      const response = await loadList(SHIFTS_URL, queryString, token);
      
      setShifts(prevShifts => [...prevShifts, ...response.rows]);
      setTotalCount(response.meta.size);
      
      if (offset === 0 && response.rows.length > 0) {
        setSelectedShift(response.rows[0]);
      }
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message || 'Не удалось загрузить смены.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, [offset]);

  const loadMoreShifts = () => {
    setOffset(prevOffset => prevOffset + LIMIT);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/4 bg-white overflow-y-auto border-r border-gray-200">
        {shifts.map((shift) => (
          <div
            key={shift.meta.id}
            className={`p-4 cursor-pointer hover:bg-gray-50 ${
              selectedShift?.meta.id === shift.meta.id ? 'bg-blue-50' : ''
            }`}
            onClick={() => setSelectedShift(shift)}
          >
            <div className="flex items-center text-gray-600 mb-1">
              {new Date(shift.open).toLocaleDateString()}
            </div>
            <div className="font-semibold text-gray-800">
              {shift.meta.name}
            </div>
            <div className="text-sm text-gray-500">
              {formatDuration(shift.duration)}
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-green-600">
                +{shift.doc_stats.sales.sum?.toFixed(2) || '0.00'}
              </span>
              <span className="text-red-600">
                -{shift.doc_stats.return_sales.sum?.toFixed(2) || '0.00'}
              </span>
            </div>
          </div>
        ))}
        {shifts.length < totalCount && (
          <div className="p-4 text-center">
            <button
              onClick={loadMoreShifts}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load more'}
            </button>
          </div>
        )}
      </div>
      <div className="w-3/4 overflow-y-auto p-6">
        {selectedShift && <Sales shift={selectedShift} />}
      </div>
    </div>
  );
};

const Sales = ({ shift }) => {
  const [docs, setDocs] = useState([]);
  const [docsOffset, setDocsOffset] = useState(0);
  const [docsTotal, setDocsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { token, showToast } = useStateContext();

  const fetchDocs = async () => {
    try {
      setLoading(true);
      const queryString = `?filter=shift=${shift.meta.id};kkm=True&offset=${docsOffset}&limit=${LIMIT}`;
      const response = await loadList(DOCS_URL, queryString, token);
      
      setDocs(prevDocs => [...prevDocs, ...response.rows]);
      setDocsTotal(response.meta.size);
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message || 'Не удалось загрузить документы.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDocs([]);
    setDocsOffset(0);
    if (shift) {
      fetchDocs();
    }
  }, [shift]);

  useEffect(() => {
    if (docsOffset > 0) {
      fetchDocs();
    }
  }, [docsOffset]);

  const loadMoreDocs = () => {
    setDocsOffset(prevOffset => prevOffset + LIMIT);
  };

  return (
    <div className="font-sans max-w-3xl mx-auto text-gray-800">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-800">{shift.meta.name}</h1>
        <p className="text-sm text-gray-600">
          {shift.store.meta.name} • {new Date(shift.open).toLocaleDateString()} — 
          {new Date(shift.closed).toLocaleDateString()}
        </p>
      </div>
      <div className="flex mb-8">
        <div className="w-1/2 pr-2">
          <div className="border border-gray-300 rounded p-4">
            <h2 className="text-xl font-semibold mb-2 text-gray-800">Продажи</h2>
            <div className="text-4xl font-bold mb-4 text-gray-800">
              {shift.doc_stats.sales.count}
            </div>
            <div className="space-y-2 text-gray-700">
              <div className="flex justify-between font-semibold">
                <span>Сумма продаж
                </span>
                <span>{shift.doc_stats.sales.sum?.toFixed(2) || '0.00'} сом</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 pl-2">
          <div className="border border-gray-300 rounded p-4">
            <h2 className="text-xl font-semibold mb-2 text-gray-800">Возвраты</h2>
            <div className="text-4xl font-bold mb-4 text-gray-800">
              {shift.doc_stats.return_sales.count}
            </div>
            <div className="space-y-2 text-gray-700">
              <div className="flex justify-between font-semibold">
                <span>Сумма возвратов продаж</span>
                <span>{shift.doc_stats.return_sales.sum?.toFixed(2) || '0.00'} сом</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-gray-300 rounded p-4 mb-8">

        <h2 className="text-xl font-semibold mb-2 text-gray-800">Касса</h2>
        <div className="space-y-2 text-gray-700">
          <div className="flex justify-between">
            <span>Сумма на начало смены</span>
            <span>{shift.open_money} сом</span>
          </div>
          <div className="flex justify-between">
            <span>Сумма всех взносов</span>
            <span>{shift.doc_stats.sales.sum?.toFixed(2) || '0.00'} сом</span>
          </div>
          <div className="flex justify-between">
            <span>Сумма всех выносов</span>
            <span>{(shift.doc_stats.sales.sum-shift.close_money)?.toFixed(2) || '0.00'} сом</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>Сумма на конец смены</span>
            <span>{shift.close_money} сом</span>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {docs.map((doc) => (
          <SaleItem key={doc.meta.id} doc={doc} />
        ))}
        {docs.length < docsTotal && (
          <div className="p-4 text-center">
            <button
              onClick={loadMoreDocs}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? 'Загрузка...' : 'Загрузить еще'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};



const formatDuration = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours} ч ${minutes} мин ${remainingSeconds} сек`;
};

const SaleItem = ({ doc }) => {
  const [isPaidOpen, setIsPaidOpen] = useState(false);
  const [isItemsOpen, setIsItemsOpen] = useState(false);

  console.log('SaleItem', doc);
  return (
    <div className="border border-gray-300 rounded mb-4">
      <div className="flex justify-between items-center p-4 border-b border-gray-300">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 flex items-center">
            Продажа #{doc.number}
            <FaPrint className="ml-2 text-gray-500" size={18} />
          </h3>
          <p className="text-sm text-gray-600">{new Date(doc.created).toLocaleDateString('ru-RU')} • {doc.store ? doc.store.meta.name : ' - '}</p>
          <p className="text-sm text-gray-600">{doc.client ? doc.client.meta.name : ' - '}</p>
        </div>
        <span className="text-xl font-bold text-gray-800">{doc.sum} сом</span>
      </div>
      <div className="bg-orange-100 p-2 text-orange-800">{doc.comment}</div>
      <div 
        className="p-4 flex justify-between items-center cursor-pointer"
        onClick={() => setIsPaidOpen(!isPaidOpen)}
      >
        <span className="text-gray-700">Оплачен</span>
        {isPaidOpen ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </div>
      {isPaidOpen && (
        <div className="px-4 py-2 bg-gray-50">
          <table className="w-full">
            <thead>
              <tr className="text-left text-gray-600">
                <th>Номер</th>
                <th>Клиент</th>
                <th>Дата</th>
                <th>Сумма</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{doc.number}</td>
                <td>{doc.client ? doc.client.meta.name : ' - '}</td>
                <td>{new Date(doc.created).toLocaleDateString('ru-RU')}</td>
                <td>{doc.sum}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div 
        className="px-4 py-2 border-t border-gray-300 flex justify-between items-center cursor-pointer"
        onClick={() => setIsItemsOpen(!isItemsOpen)}
      >
        <span className="text-gray-700">Список товаров</span>
        <span className="text-gray-600 flex items-center">
          {doc.positions.meta.size} поз. 
          {isItemsOpen ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
        </span>
      </div>
      {isItemsOpen && (
        <Positions doc={doc} />
      )}
    </div>
  );
};

const Positions = ({ doc }) => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, showToast } = useStateContext();

  const fetchPositions = async () => {
    try {
      setLoading(true);
      const response = await loadList('/docs/admin-api/positions', `?filter=doc=${doc.meta.id}`, token);
      setPositions(response.rows);
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message || 'Не удалось загрузить позиции.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, []);

  return (
    <div className="px-4 py-2 bg-gray-50">
      <table className="w-full">
        <thead>
          <tr className="text-left text-gray-600">
            <th>Название</th>
            <th>Количество</th>
            <th>Цена</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {loading && (<tr><td colSpan="4">Загрузка...</td></tr>)}
          {positions.map((position) => (
            <tr key={position.meta.id}>
              <td>{position.product.meta.name}</td>
              <td>{position.quantity}</td>
              <td>{position.price}</td>
              <td>{position.sum}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Shifts;