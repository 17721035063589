import React from 'react';
import { List, SupplierGroupItem } from "components";

const formatDate = (date) => new Date(date).toLocaleDateString();

const SupplierGroupsList = (props) => {
    const filterOptions = [
        {
            key: 'name',
            label: 'Имя',
            field:  (props) => (
                <input 
                    type="text"
                    placeholder="Наименование"
                    value={props.value}
                    className={props.className}
                    onChange={(e) => props.onChange(e.target.value)}
                />
            ),
            initialValue: ''
        },
    ];

    const columns = [
        { key: 'id', label: 'ID', hide: true },
        { key: 'name', label: 'Наименование' },
        { key: 'members', label: 'Поставщики', component: (item) => <>{item.members && item.members.meta.size}</> },
        { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    ];

    return (
        <List
            targetUrl="/parties/admin-api/supplier-groups"
            filterOptions={filterOptions}
            columns={columns}
            ItemComponent={SupplierGroupItem}
        />
    );
};

export default SupplierGroupsList;
