import React, { useState, Suspense } from "react";
import { OrdersList, OrderStats, Loading, Tooltip, OrderTemplateForm } from "components";
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from "contexts/ContextProvider";
import { Info } from 'lucide-react';




const Orders = () => {
  const { currentColor } = useStateContext();

  return (
    <>
      <Suspense fallback={<Loading/>}>

      <div className="bg-gray-100 py-4 px-6 flex justify-between items-center relative">
        <div className="flex items-center relative">
          <Tooltip trigger='click' className="mr-2">
            <button className="text-gray-700 hover:text-gray-900 mr-2 mt-1">
              <Info size={20} />
            </button>
          </Tooltip>
          <h1 className="text-2xl font-bold">Заказы</h1>
          
        </div>
      </div>
        <OrderStats/>
        <OrdersList/>
      </Suspense>
    </>
  );
};

export default Orders;
