import React, { useState, useEffect } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
  Settings,
} from 'lucide-react';
import { loadList, exportStats } from 'services';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';
import { Tooltip, Filter } from 'components';



const Stats = ({ targetUrl, filterOptions, ChartComponent }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const { openModal } = useLayout();
  const { currentColor, showToast } = useStateContext();



  const constructQueryParams = (searchValue, filters) => {
    const queryParams = [];

    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
    }
    const filterParams = [];
    filterOptions.forEach(({key, postProcess}) => {
      if (filters[key]) {
        if (postProcess) {
          filterParams.push(postProcess(filters[key]));
        } else if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
          filterParams.push(`${key}=[${filters[key].map((item) => item.id ? item.id : item).join(',')}]`);
          }
        } else {
          filterParams.push(`${key}=${filters[key]}`);
        }
      }
    });

    if (filterParams.length > 0) {
      queryParams.push(`filter=${filterParams.join(';')}`);
    }

    return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  };


  const fetchStats = async (searchValue = '', filters=[]) => {
    try {
      console.log('fetchStats');
      setLoading(true);
      const queryString = constructQueryParams(searchValue, filters);
      const response = await loadList(`${targetUrl}`, queryString);
      setStats(response);
    } catch (error) {
        showToast({ 
        title: 'Error!', 
        content: error.message || 'Something went wrong!',
        type: 'error',
        });
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <div className=" p-4 space-y-4">
      <Filter
        filterOptions={filterOptions}
        fetchItems={fetchStats}
      />
      {ChartComponent && <ChartComponent data={stats} />}
    </div>
  );
};

export default Stats;
