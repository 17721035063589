import React, { useEffect, useState, memo } from 'react';
import { useStateContext } from 'contexts/ContextProvider';
import {
  ShoppingCart,
  Package,
  Wrench,
  CheckCircle,
  Truck,
  XCircle,
  Phone,
  Wallet
} from 'lucide-react';
import { orderService } from 'services';

const formatDate = (dateString) => {
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric'
  };
  return new Date(dateString).toLocaleDateString('ru-RU', options);
};

// Status configuration object
const STATUS_CONFIG = {
  cart: { label: 'Корзина', Icon: ShoppingCart },
  ordered: { label: 'Оформлен', Icon: Package },
  preparing: { label: 'Сборка', Icon: Wrench },
  ready: { label: 'Готов', Icon: CheckCircle },
  delivering: { label: 'Доставка', Icon: Truck },
  completed: { label: 'Завершен', Icon: CheckCircle },
  canceled: { label: 'Отменен', Icon: XCircle },
};

const STATUS_ORDER = Object.keys(STATUS_CONFIG);

const STATUS_TRANSLATIONS = {
  cart: 'Корзина',
  ordered: 'Оформлен',
  preparing: 'Сборка',
  ready: 'Готов',
  delivering: 'Доставка',
  completed: 'Завершен',
  canceled: 'Отменен',
};

// Memoized OrderItem component for better performance
const OrderItem = memo(({ item, afterStatusUpdate }) => {
  const [order, setOrder] = useState(item);
  const [editMode, setEditMode] = useState({
    status: false,
    delivery: false,
    payment: false
  });
  const { showToast, token } = useStateContext();

  useEffect(() => {
    const loadOrder = async () => {
      if (item.id) {
        setOrder(item);
        return;
      }

      try {
        const response = await orderService.getById(item.meta.id, token);
        setOrder(response.response);
      } catch (error) {
        showToast({
          title: 'Ошибка!',
          content: 'Не удалось загрузить заказ',
          type: 'error'
        });
      }
    };

    loadOrder();
  }, [item, token, showToast]);

  if (!order.id) {
    return (
      <div className="bg-white p-4 rounded-lg shadow">
        <p>Заказ не найден</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow space-y-4">
      <OrderHeader order={order} />
      <OrderContactInfo order={order} />
      <StatusIndicator 
        defaultStatus={order.status}
        orderId={order.id}
        afterStatusUpdate={afterStatusUpdate}
      />
      <OrderItems items={order.items_list} />
      <OrderSummary total={order.total} />
    </div>
  );
});

// Separate components for better organization and reusability
const OrderHeader = memo(({ order }) => (
  <div>
    <h2 className="text-lg font-bold">Заказ №{order.id}</h2>
    <p>Клиент: {order.client?.meta.name || 'N/A'}</p>
    <p>Магазин: {order.store?.meta.name ||'N/A'}</p>
    <p>Адрес: {order.address?.meta.name || 'N/A'}</p>
    <p>Время заказа: {formatDate(order.ordered)}</p>
  </div>
));

const OrderContactInfo = memo(({ order }) => (
  <div className="space-y-4">
    <div className="flex items-center justify-between p-4">
      <Wallet className="text-blue-500 w-5 h-5" />
      <span className="text-sm">
        Способ оплаты: {order.details?.payment_option ?? 'N/A'}
      </span>
    </div>
    <div className="flex items-center justify-between p-4">
      <Phone className="text-blue-500 w-5 h-5" />
      <span className="text-sm">
        Контактный телефон: {order.details?.contact_phone ?? 'N/A'}
      </span>
    </div>
  </div>
));

const OrderItems = memo(({ items }) => (
  <ul className="divide-y divide-gray-200">
    {items.map((item, index) => (
      <li key={index} className="py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img 
            src={item.listing.image_url} 
            alt={item.listing.title} 
            className="h-12 w-12 object-cover mr-4" 
          />
          <div>
            <p className="font-medium">{item.listing.title}</p>
            <p className="text-sm text-gray-600">
              Цена: {item.total.price} сом
            </p>
          </div>
        </div>
        <span>Количество: {item.total.quantity} шт</span>
      </li>
    ))}
  </ul>
));

const OrderSummary = memo(({ total }) => (
  <div className="mt-4 p-4 border-t border-gray-200 space-y-2">
    <SummaryRow label="Сумма заказа" value={`${total.sum} сом`} />
    <SummaryRow label="Скидка" value={`${total.discount_sum} сом`} />
    <SummaryRow label="Доставка" value={`${total.delivery_fee} сом`} />
    <SummaryRow 
      label="Итого" 
      value={`${total.sum - total.discount_sum + total.delivery_fee} сом`} 
      isTotal 
    />
  </div>
));

const SummaryRow = memo(({ label, value, isTotal }) => (
  <div className="flex justify-between">
    <span className={isTotal ? 'font-semibold' : ''}>
      {label}
    </span>
    <span className={isTotal ? 'font-semibold' : ''}>
      {value}
    </span>
  </div>
));

const StatusIndicator = memo(({ defaultStatus, orderId, afterStatusUpdate }) => {
  const [currentStatus, setCurrentStatus] = useState(defaultStatus);
  const { showToast, token } = useStateContext();

  const handleStatusUpdate = async (status) => {
    const confirm = window.confirm(
      `Вы уверены, что хотите обновить статус заказа на "${STATUS_TRANSLATIONS[status]}"?`
    );
    
    if (!confirm) return;

    try {
      await orderService.updateStatus(orderId, status, token);
      showToast({ 
        title: 'Успех!', 
        content: 'Статус успешно обновлен!', 
        type: 'success' 
      });
      setCurrentStatus(status);
      if (afterStatusUpdate) {
        afterStatusUpdate(orderId, status);
      }
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: 'Не удалось обновить статус заказа',
        type: 'error'
      });
    }
  };

  const getStatusClasses = (status) => {
    const isActive = STATUS_ORDER.indexOf(status) <= STATUS_ORDER.indexOf(currentStatus);
    return `flex items-center justify-center px-4 py-2 rounded-full transition-colors 
      ${isActive ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'} 
      space-x-2`;
  };

  return (
    <div className="flex space-x-2 justify-center text-sm">
      {STATUS_ORDER.map((status) => {
        const { label, Icon } = STATUS_CONFIG[status];
        return (
          <button
            key={status}
            onClick={() => handleStatusUpdate(status)}
            className={`${getStatusClasses(status)} flex flex-col items-center p-2`}
          >
            <Icon className="w-6 h-6 mb-1" />
            <span className="font-medium text-xs">{label}</span>
          </button>
        );
      })}
    </div>
  );
});

OrderItem.displayName = 'OrderItem';
OrderHeader.displayName = 'OrderHeader';
OrderContactInfo.displayName = 'OrderContactInfo';
OrderItems.displayName = 'OrderItems';
OrderSummary.displayName = 'OrderSummary';
SummaryRow.displayName = 'SummaryRow';
StatusIndicator.displayName = 'StatusIndicator';

export default OrderItem;