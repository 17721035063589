import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useStateContext } from 'contexts/ContextProvider';
import { Search, Check, ChevronDown, X } from 'lucide-react';
import { loadList } from 'services';

const ItemSelector = ({
    targetUrl,
    options = [],
    onSelect,
    onRemove,
    selectedItems = [],
    getButtonText,
    placeholder = "Select items...",
    searchPlaceholder = "Search items...",
    noItemsFoundText = "No items found",
    filterString,   
    isMultiSelect = true,
    shouldCloseOnSelect = false,
}) => {
    const { token } = useStateContext();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const dropdownRef = useRef(null);
    const isInitialMount = useRef(true);
    const isOfflineMode = !targetUrl && options.length > 0;
    const transformResponse = useCallback((response) => {
        return response.rows.map(item => ({
            id: item.meta ? item.meta.id : item.id,
            name: item.meta ? item.meta.name : item.name,
        }));
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            if (isOfflineMode) {
                setItems(options);
            } else {
                loadItems();
            }
        }
    }, [isOfflineMode, options]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const loadItems = useCallback(async (searchString = "") => {
        if (isOfflineMode) {
            const filteredItems = options.filter(item =>
                item.name.toLowerCase().includes(searchString.toLowerCase())
            );
            setItems(filteredItems);
            return;
        }

        setLoading(true);
        try {
            const queryParams = [
                searchString && `search=${searchString}`,
                filterString && `filter=${filterString}`,
            ].filter(Boolean);

            const queryString = queryParams.length > 0
                ? `?${queryParams.join('&')}`
                : '';

            const response = await loadList(targetUrl, queryString, token);
            const transformedResponse = transformResponse ? transformResponse(response) : response.rows;
            setItems(transformedResponse);
        } catch (error) {
            console.error('Failed to load items:', error);
        } finally {
            setLoading(false);
        }
    }, [isOfflineMode, options, targetUrl, token, transformResponse]);

    const handleSearch = useCallback((value) => {
        setSearchValue(value);
        if (isOfflineMode) {
            loadItems(value);
            return;
        }

        const debounceTimeout = setTimeout(() => {
            loadItems(value);
        }, 300);

        return () => clearTimeout(debounceTimeout);
    }, [loadItems, isOfflineMode]);

    const handleItemSelect = useCallback((item) => {
        if (!selectedItems.some(e => e.id === item.id)) {
            onSelect(item);
            if (shouldCloseOnSelect) {
                setOpen(false);
            }
        } else {
            onRemove(item.id);
        }
    }, [isMultiSelect, onSelect, onRemove, selectedItems, shouldCloseOnSelect]);

    const defaultGetButtonText = useCallback(() => {
        if (selectedItems.length === 0) return placeholder;
        if (selectedItems.length === 1) return selectedItems[0].name;
        return `Выбрано ${selectedItems.length}`;
    }, [selectedItems, placeholder]);

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <button
                type="button"
                onClick={() => setOpen(!open)}
                className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
                <div className="flex items-center justify-between">
                    <span className={`block truncate ${selectedItems.length === 0 && 'text-gray-500'}`}>
                        {getButtonText ? getButtonText(selectedItems) : defaultGetButtonText()}
                    </span>
                    <ChevronDown className="w-4 h-4 ml-2 text-gray-400" />
                </div>
            </button>

            {isMultiSelect && selectedItems.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                    {selectedItems.map((item) => (
                        <div
                            key={item.id}
                            className="flex items-center gap-1 px-2 py-1 text-sm bg-blue-100 text-blue-800 rounded-full"
                        >
                            <span>{item.name}</span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove(item.id);
                                }}
                                className="p-1 hover:bg-blue-200 rounded-full"
                            >
                                <X className="w-3 h-3" />
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {open && (
                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                    <div className="flex items-center px-3 py-2 border-b">
                        <Search className="w-4 h-4 text-gray-400" />
                        <input
                            type="text"
                            className="w-full px-2 py-1 ml-2 text-sm focus:outline-none"
                            placeholder={searchPlaceholder}
                            value={searchValue}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>

                    <div className="max-h-60 overflow-auto">
                        {loading ? (
                            <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
                        ) : items.length === 0 ? (
                            <div className="px-4 py-2 text-sm text-gray-500">{noItemsFoundText}</div>
                        ) : (
                            items.map((item) => (
                                <button
                                    key={item.id}
                                    className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleItemSelect(item);
                                    }}
                                >
                                    <span>{item.name}</span>
                                    {selectedItems.some(e => e.id === item.id) && (
                                        <Check className="w-4 h-4 text-blue-500" />
                                    )}
                                </button>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ItemSelector;