import React, { Suspense, useState, useEffect } from "react";


// export const lazyWithDelay = (importFunc, delay = 1000) => {
//   return React.lazy(() =>
//     Promise.all([
//       importFunc(),
//       new Promise((resolve) => setTimeout(resolve, delay)), // Simulate delay
//     ]).then(([module]) => module)f
//   );
// };

// const Dashboard = lazyWithDelay(() => import("./pages/Dashboard"), 3000); // 3-second delay
// const UnderConstruction = lazyWithDelay(() => import("./pages/UnderConstruction"), 3000); // 3-second delay
// const Clients = lazyWithDelay(() => import("./pages/Parties/Clients"), 3000); // 3-second delay

import { Loading } from 'components';

import {

    // !Root Layout
    RootLayout,
    ErrorPage,
    UnderConstruction,

    // !General Pages
    Dashboard,
    TestRootLayout, TestPages,
    OrdersTemplate,
    


    // !Company pages
    Employees,
    Consultants,
    EmployeeGroups, 
    Stores,
    Projects,
    

    // !Consultants pages
    ConsultantsRootLayout, ConsultantReport,

    // Consultants, AddConsultant, ViewConsultant, EditConsultant, consultantDetailLoader,
    // !Doc pages
    DocsRootLayout, Docs, ViewDoc, SetConsultants, DocStats, StoreStats, ConsultantStats, 

    // !Dev pages
    DevRootLayout,
    SectionRootLayout, Sections, AddSection, ViewSection, EditSection,

    // !Product pages
    ProductRootLayout,
    Products,
    Categories,
    ProductGroups,


    // !Parties pages
    
    //Clients
    Clients,
    EditClient,
    ClientGroups,
    // !Suppliers
    Suppliers,
    SupplierGroups,


    // !CRM pages
    CRMStats,
    CrmMap,
    CRMRootLayout,

    // !App pages
    AppRootLayout, QA, ContactLinks,
    AssortmentsRootLayout, Assortments, AddAssortment, ViewAssortment, EditAssortment, assortmentDetailLoader,
    BannersRootLayout, Banners, AddBanner, ViewBanner, EditBanner, bannerDetailLoader,
    BrandsRootLayout, Brands, AddBrand, ViewBrand, EditBrand,
    ListingsRootLayout, Listings, AddListing, ViewListing, EditListing,
    PostsRootLayout, Posts, AddPost, ViewPost, EditPost, postDetailLoader,


    // !Orders
    Orders,
    OrderDetail,
    
    // !Notifications
    Tags,
    Notifications, AddNotification, EditNotification, NotificationDetail, NotificationReport, notificationDetailLoader,
    NotificationTemplates, 

    // !POS pages
    PosRootLayout,
    OPaymentRootLayout, ViewOPayment, AddOPayment, OPayments,
    BonusRootLayout, Bonuses, AddBonus, EditBonus, Shifts,


    // !Other pages
    Customers,
    Kanban,
    ColorPicker,
    Editor,
    Calendar
} from './pages';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";





function App() {

    return (
        <Router>
        <Routes>


          <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
              <Route index path='dashboard' element={<Suspense fallback={<Loading />}><DocStats /></Suspense>} />

              <Route path="stats">
                <Route path='consultants' element={<ConsultantStats />} />
                <Route index path='stores' element={<StoreStats />} />
              </Route>
              
              {/* Company Routes */}
              <Route path="company">
                <Route index element={<UnderConstruction />} />
                <Route path="projects">
                  <Route index element={<Projects />} />
                  <Route path="new" element={<UnderConstruction />} />
                  <Route path=":id" element={<UnderConstruction />} />
                </Route>
                <Route path="employees"  element={<Employees />} />
                <Route path="consultants"  element={<Consultants />} />
                <Route path="employee-groups"  element={<EmployeeGroups key="activeEmployeeGroups" />} />
                <Route path="stores" element={<Stores key="activeStores" />} />
              </Route>

              {/* Dev Routes */}
              <Route path="dev" element={<Suspense fallback={<Loading />}><DevRootLayout /></Suspense>}>
                <Route path="sections" element={<SectionRootLayout />}>
                  <Route index element={<Sections />} />
                  <Route path="archived" element={<Sections archived={true} />} />
                  <Route path="new" element={<AddSection />} />
                  <Route path=":id">
                    <Route index element={<ViewSection />} />
                    <Route path="edit" element={<EditSection />} />
                  </Route>
                </Route>

                
                <Route path="test" element={<TestRootLayout />}>
                  <Route index element={<UnderConstruction />} />
                  {TestPages.map((Component, index) => (
                    <Route 
                      key={index + 1} 
                      path={`${index + 1}`} 
                      element={<Component />} 
                    />
                  ))}
                </Route>
              </Route>

              {/* Consultants Routes */}
              <Route path="consultants" element={<Suspense fallback={<Loading />}><ConsultantsRootLayout /></Suspense>}>
                <Route path="reports" element={<ConsultantReport />} />
                <Route path="tasks" element={<UnderConstruction />} />
                <Route path="schedule" element={<UnderConstruction />} />
                <Route path="settings" element={<UnderConstruction />} />
              </Route>

              {/* Docs Routes */}
              <Route path="docs">
                <Route index element={<Docs />} />
                <Route path="set-consultants" element={<SetConsultants />} />
                <Route path=":id" element={<ViewDoc />} />
              </Route>

              {/* Products Routes */}
              <Route path="products">
                <Route index element={<Products />} />
                <Route path="categories" element={<Categories />} />
                <Route path="groups" element={<ProductGroups />} />
              </Route>

              {/* Parties Routes */}

              <Route path="parties">
                <Route path="clients" element={<Clients />} />
                <Route  path="client-groups" element={<ClientGroups />} />


                <Route path="suppliers" element={<Suspense fallback={<Loading />}><Suppliers /></Suspense>} />
                <Route path="supplier-groups" element={<SupplierGroups />} />
              </Route>

              {/* CRM Routes */}
              <Route path="crm">
                <Route index element={<CRMStats />} />
                <Route path="map" element={<CrmMap />} />
                
                <Route path="orders"  element={<Orders />} />

                <Route path="notifications">
                  <Route index element={<Notifications />} />
                  <Route path="templates" element={<NotificationTemplates />} />
                </Route>
              </Route>

              {/* App Routes */}
              <Route path="app">
                <Route index element={<UnderConstruction />} />
                
                <Route path="assortments" element={<AssortmentsRootLayout />}>
                  <Route index element={<Assortments status="active" key="activeAssortments" />} />
                  <Route path="active" element={<Assortments status="active" key="activeAssortments" />} />
                  <Route path="catalog" element={<Assortments status="catalog" key="catalogAssortments" />} />
                  <Route path="archived" element={<Assortments status="archived" key="archivedAssortments" />} />
                  <Route path="new" element={<AddAssortment />} />
                  <Route path=":id">
                    <Route index element={<ViewAssortment />} />
                    <Route path="edit" element={<EditAssortment key={new Date().toISOString()} />} />
                  </Route>
                </Route>

                <Route path="listings" element={<ListingsRootLayout />}>
                  <Route index element={<Listings status="active" key="activeListings" />} />
                  <Route path="active" element={<Listings status="active" key="activeListings" />} />
                  <Route path="archived" element={<Listings status="archived" key="archivedListings" />} />
                  <Route path="new" element={<AddListing />} />
                  <Route path=":id">
                    <Route index element={<ViewListing />} />
                    <Route path="edit" element={<EditListing />} />
                  </Route>
                </Route>

                <Route path="posts" element={<PostsRootLayout />}>
                  <Route index element={<Posts key="activePosts" status="active" />} />
                  <Route path="active" element={<Posts key="activePosts" status="active" />} />
                  <Route path="archived" element={<Posts key="archivedPosts" status="archived" />} />
                  <Route path="new" element={<AddPost />} />
                  <Route path=":id">
                    <Route index element={<ViewPost />} />
                    <Route path="delete" element={<ViewPost />} />
                    <Route path="edit" element={<EditPost />} />
                  </Route>
                </Route>

                <Route path="banners" element={<BannersRootLayout />}>
                  <Route index element={<Banners key="activeBanners" status="active" />} />
                  <Route path="active" element={<Banners key="activeBanners" status="active" />} />
                  <Route path="archived" element={<Banners archived={true} key="archivedBanners" status="archived" />} />
                  <Route path="new" element={<AddBanner />} />
                  <Route path=":id">
                    <Route index element={<ViewBanner />} />
                    <Route path="edit" element={<EditBanner />} />
                  </Route>
                </Route>

                <Route path="brands" element={<BrandsRootLayout />}>
                  <Route index element={<Brands />} />
                  <Route path="archived" element={<Brands archived={true} />} />
                  <Route path="new" element={<AddBrand />} />
                  <Route path=":id">
                    <Route index element={<ViewBrand />} />
                    <Route path="edit" element={<EditBrand />} />
                  </Route>
                </Route>

                <Route path="qas" element={<QA />} />
                <Route path="contact-links" element={<ContactLinks />} />
              </Route>

              {/* POS Routes */}
              <Route path="pos">
                <Route index element={<UnderConstruction />} />
                <Route path="shifts" element={<Shifts />} />

                <Route path="edit-client" element={<EditClient />} />
              </Route>

                
          </Route>
          
        </Routes>
    </Router>

    );
}

export default App;



