import React from 'react';
import { ItemSelector, List, EmployeeGroupItem } from 'components';


const formatDate = (date) => new Date(date).toLocaleDateString();

const EmployeeGroupsList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Name',
      field:  (props) => (
        <input 
          type="text"
          placeholder="Наименование"
          value={props.value}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      initialValue: ''
    },
    {
      key: 'group',
      label: 'Groups',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/admin-api/employee-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите сотрудника"
          searchPlaceholder="Поиск сотрудника..."
          noItemsFoundText="Сотрудники не найдены"
          filterString="group=[5];"
          isMultiSelect={true}
        />
      ),
      initialValue: props.group ? [props.group] : [],
    },
    {
      key: 'stores',
      label: 'Stores',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/admin-api/stores"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите магазин"
          searchPlaceholder="Поиск магазина..."
          noItemsFoundText="Магазины не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true},
    { key: 'name', label: 'name' },
    { key: 'members', label: 'members', component: (item) => <>{item.members && item.members.meta.size}</> },
    { key: 'created', label: 'Created', component: (item) => <>{formatDate(item.created)}</> },
  ];


  return (
    <List
      targetUrl="/admin-api/employee-groups"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={EmployeeGroupItem}
    />
  );
};

export default EmployeeGroupsList;
