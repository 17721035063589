import React from 'react';
import { Stats, DateRangePicker, ItemSelector } from 'components';
import { DailyStoreStats } from 'components';


const StoreStats = (props) => {
  const filterOptions = [
    {
      key: 'date',
      label: 'Дата',
      field: ({ value, onChange, className }) => (
          <DateRangePicker 
            stringStartDate={value[0]}
            stringEndDate={value[1]}
            onApply={({startDate, endDate}) => onChange([startDate, endDate])}
          />
      ),
      initialValue: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-CA'),
        new Date().toISOString().split('T')[0]
      ],
      postProcess: (value) => {
        return `date__gte=${value[0]};date__lt=${value[1]} 23:59:59`;
      }
    },
    {
      key: 'client',
      label: 'Клиент',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/clients"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите клиента"
          searchPlaceholder="Поиск клиента..."
          noItemsFoundText="Клиенты не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.client ? [props.client] : [],
    },
    {
      key: 'supplier',
      label: 'Поставщик',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/suppliers"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите поставщика"
          searchPlaceholder="Поиск поставщика..."
          noItemsFoundText="Поставщики не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.supplier ? [props.supplier] : [],
    },
    {
      key: 'consultant',
      label: 'Консультант',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/admin-api/employees"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите сотрудника"
          searchPlaceholder="Поиск сотрудника..."
          noItemsFoundText="Сотрудники не найдены"
          filterString="group=[5];"
          isMultiSelect={true}
        />
      ),
      initialValue: props.employee ? [props.employee] : [],
    },
    {
      key: 'from_store',
      label: 'Из магазина',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/admin-api/stores"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите магазин"
          searchPlaceholder="Поиск магазина..."
          noItemsFoundText="Магазины не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
    {
      key: 'is_kkm',
      label: 'ККМ',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="True">Да</option>
          <option value="False">Нет</option>
        </select>
      ),
      initialValue: '',
    },
    {
      key: 'client__is_token_active',
      label: 'Клиент с приложением',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="True">Да</option>
          <option value="False">Нет</option>
        </select>
      ),
      initialValue: '',
    },
    {
      key: 'style',
      label: 'Тип документа',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="sales">Продажа</option>
          <option value="return_sales">Возврат продажи</option>
          <option value="movements">Перемещение</option>
          <option value="purchases">Закупка</option>
          <option value="return_purchases">Возврат закупки</option>
          <option value="changes">Изменения</option>
        </select>
      ),
      initialValue: 'sales',
    }
  ];

  return (
    <Stats
      targetUrl={props.targetUrl || "/docs/admin-api/docs/stats-by-store/"}
      filterOptions={filterOptions}
      ChartComponent={DailyStoreStats}
    />
  );
};

export default StoreStats;
