import React, { useState, useEffect, useRef } from 'react';
import { taskService } from 'services';
import { Calendar, Edit2, Save, X, Paperclip, ExternalLink, Trash2 } from 'lucide-react';
import { OrderItem } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';

const TaskItem = ({ task }) => {
    const [editMode, setEditMode] = useState({
        name: false,
        text: false,
        deadline: false
    });
    const [formData, setFormData] = useState({
        name: task.name,
        text: task.text,
        deadline: task.deadline || ''
    });
    const { showToast, token, user } = useStateContext();
    const { openModal } = useLayout();


    const handleEdit = async (field) => {
        if (editMode[field]) {
            try {
                let updateFn;
                switch (field) {
                    case 'name':
                        updateFn = () => taskService.updateName(task.id, formData.name, token);
                        break;
                    case 'text':
                        updateFn = () => taskService.updateText(task.id, formData.text, token);
                        break;
                    case 'deadline':
                        updateFn = () => taskService.updateDeadline(task.id, formData.deadline, token);
                        break;
                }
                const response = await updateFn();
                if(response.status === 1){
                    setFormData(prev => ({ ...prev, [field]: task[field] }));
                    showToast({
                        title: 'Error!',
                        content: response.message || 'Could not update task',
                        type: 'error'
                    });
                    return;
                }
                showToast({
                    title: 'Success!',
                    content: 'Task updated successfully',
                    type: 'success'
                });
            } catch (error) {
                showToast({
                    title: 'Error!',
                    content: error.message || 'Could not update task',
                    type: 'error'
                });
            }
        }
        setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleTargetClick = () => {
        if (task.target) {
            openModal({
                component: <OrderItem item={task.target} />,
                title: 'Task Target',
                type: 'right'
            });
        }
    };

    return (
        <div className="flex-1 flex flex-col h-full min-h-0">
            {/* Task Details Section */}
            <div className="p-4 space-y-4 border-b">
                {/* Task Name */}
                <div className="flex items-start justify-between gap-2">
                    {editMode.name ? (
                        <input
                            type="text"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            className="flex-1 px-2 py-1 border rounded"
                        />
                    ) : (
                        <h2 className="text-lg font-bold">{formData.name}</h2>
                    )}
                    <button
                        onClick={() => handleEdit('name')}
                        className="p-1 text-gray-500 hover:text-gray-700"
                    >
                        {editMode.name ? <Save className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
                    </button>
                </div>

                {/* Task Description */}
                <div className="flex items-start gap-2">
                    {editMode.text ? (
                        <textarea
                            value={formData.text}
                            onChange={(e) => setFormData(prev => ({ ...prev, text: e.target.value }))}
                            className="flex-1 px-2 py-1 border rounded"
                            rows={3}
                        />
                    ) : (
                        <p className="flex-1 text-sm text-gray-600">{formData.text}</p>
                    )}
                    <button
                        onClick={() => handleEdit('text')}
                        className="p-1 text-gray-500 hover:text-gray-700"
                    >
                        {editMode.text ? <Save className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
                    </button>
                </div>

                {/* Task Metadata */}
                <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center gap-4">
                        <div className="flex items-center gap-2">
                            <Calendar className="w-4 h-4 text-gray-500" />
                            {editMode.deadline ? (
                                <input
                                    type="datetime-local"
                                    value={formData.deadline}
                                    onChange={(e) => setFormData(prev => ({ ...prev, deadline: e.target.value }))}
                                    className="px-2 py-1 border rounded"
                                />
                            ) : (
                                <span className="text-gray-600">
                                    {formData.deadline ? new Date(formData.deadline).toLocaleString() : 'No deadline'}
                                </span>
                            )}
                            <button
                                onClick={() => handleEdit('deadline')}
                                className="p-1 text-gray-500 hover:text-gray-700"
                            >
                                {editMode.deadline ? <Save className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
                            </button>
                        </div>
                    </div>
                    {task.target && (
                        <button
                            onClick={handleTargetClick}
                            className="flex items-center gap-2 text-blue-500 hover:text-blue-600"
                        >
                            <Paperclip className="w-4 h-4" />
                            <span>{task.target.meta.name} target</span>
                        </button>
                    )}
                </div>
            </div>

            {/* Chat Section */}
            <TaskNotes task={task} />
            
        </div>
    );
};



const TaskNotes = ({ task }) => {
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [editingNote, setEditingNote] = useState(null);
    const [editText, setEditText] = useState('');
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const { user, token, showToast } = useStateContext();
    const [offset, setOffset] = useState(0);
    const limit = 10;
    const messagesContainerRef = useRef(null);

    const fetchNotes = async (append = false) => {
        try {
            setLoading(true);
            const response = await taskService.getNotes(
                task.id, 
                `?limit=${limit}&offset=${append ? offset + limit : 0}&order=created,asc`, 
                token
            );
            const newNotes = response.response.rows || [];
            
            if (append) {
                // When appending, add new notes to the existing ones
                setNotes(prev => {
                    const combined = [...prev, ...newNotes];
                    // Remove duplicates based on note ID
                    const uniqueNotes = Array.from(
                        new Map(combined.map(note => [note.id, note])).values()
                    );
                    // Sort by creation date
                    return uniqueNotes.sort((a, b) => 
                        new Date(a.created) - new Date(b.created)
                    );
                });
                setOffset(prev => prev + limit);
            } else {
                setNotes((prev) => {
                    return Array.from(new Map([...newNotes, ...prev].map(note => [note.id, note])).values()).sort((a, b) => new Date(a.created) - new Date(b.created));
                }); 
                setOffset(notes.length);
            }
            
            setHasMore(newNotes.length === limit);
        } catch (error) {
            showToast({
                title: 'Error!',
                content: error.message || 'Could not fetch notes',
                type: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [notes]);
    
    useEffect(() => {
        fetchNotes();
        // Set up auto-update interval for first page
        const intervalId = setInterval(() => {
            if (offset <= limit) { // Only update if on first page
                fetchNotes();
            }
        }, 60000);

        return () => clearInterval(intervalId);
    }, [task]);


    const handleLoadMore = () => {
        fetchNotes(true);
    };

    const handleEdit = (note) => {
        setEditingNote(note.id);
        setEditText(note.text);
    };

    const handleSaveEdit = async () => {
        if (!editText.trim()) return;
        
        try {
            await taskService.updateNote(task.id, editingNote, editText, token);
            setNotes(prevNotes => 
                prevNotes.map(note => 
                    note.id === editingNote 
                        ? { ...note, text: editText }
                        : note
                )
            );
            setEditingNote(null);
            showToast({
                title: 'Success!',
                content: 'Note updated successfully',
                type: 'success'
            });
        } catch (error) {
            showToast({
                title: 'Error!',
                content: error.message || 'Could not update note',
                type: 'error'
            });
        }
    };

    const handleDelete = async (noteId) => {
        if (!window.confirm('Are you sure you want to delete this note?')) return;
        
        try {
            await taskService.deleteNote(task.id, noteId, token);
            setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));
            showToast({
                title: 'Success!',
                content: 'Note deleted successfully',
                type: 'success'
            });
        } catch (error) {
            showToast({
                title: 'Error!',
                content: error.message || 'Could not delete note',
                type: 'error'
            });
        }
    };

    return (    
        <div className="flex-1 flex flex-col min-h-0 max-h-[calc(100vh-10px)]">
            {/* Messages Container */}
            <div
                ref={messagesContainerRef}
                className="flex-1 p-4 space-y-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                style={{ scrollbarWidth: 'thin', scrollbarColor: '#d4d4d4 #f3f4f6', maxHeight: 500 }}
            >   
                {/* Load More Button */}
                <div className="p-2 text-center border-b">
                    {loading ? (
                        <div className="text-sm text-gray-500">Loading...</div>
                    ) : hasMore ? (
                        <button
                            onClick={handleLoadMore}
                            className="text-sm text-blue-500 hover:text-blue-600"
                        >
                            Load more messages
                        </button>
                    ) : notes.length === 0 ? (
                        <div className="text-sm text-gray-500">No messages yet</div>
                    ) : (
                        <div className="text-sm text-gray-500">No more messages</div>
                    )}
                </div>

                {/* Messages */}
                {notes.map(note => (
                    <div 
                        key={note.id}
                        className={`flex ${note.author.meta.id === user.id ? 'justify-end' : 'justify-start'}`}
                    >
                        <div 
                            className={`max-w-[80%] p-3 rounded-lg ${
                                note.author.meta.id === user.id 
                                    ? 'bg-blue-500 text-white ml-8' 
                                    : 'bg-gray-100 text-gray-700 mr-8'
                            }`}
                        >
                            <div className="flex items-center justify-between gap-4 mb-1">
                                <span className="text-xs font-medium">
                                    {note.author.meta.name}
                                </span>
                                <span className="text-xs opacity-75">
                                    {new Date(note.created).toLocaleString()}
                                </span>
                            </div>
                            
                            {editingNote === note.id ? (
                                <div className="mt-2 space-y-2">
                                    <textarea
                                        value={editText}
                                        onChange={(e) => setEditText(e.target.value)}
                                        className="w-full p-2 text-sm text-gray-900 border rounded resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        rows={2}
                                    />
                                    <div className="flex justify-end gap-2">
                                        <button
                                            onClick={() => setEditingNote(null)}
                                            className="p-1 text-sm text-gray-500 hover:text-gray-700"
                                        >
                                            <X className="w-4 h-4" />
                                        </button>
                                        <button
                                            onClick={handleSaveEdit}
                                            className="p-1 text-sm text-green-500 hover:text-green-700"
                                        >
                                            <Save className="w-4 h-4" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <p className="text-sm">{note.text}</p>
                                    {note.author.meta.id === user.id && (
                                        <div className="flex justify-end gap-2 mt-2">
                                            <button
                                                onClick={() => handleEdit(note)}
                                                className={`p-1 ${
                                                    note.author.meta.id === user.id 
                                                        ? 'text-white/70 hover:text-white' 
                                                        : 'text-gray-500 hover:text-gray-700'
                                                }`}
                                            >
                                                <Edit2 className="w-4 h-4" />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(note.id)}
                                                className={`p-1 ${
                                                    note.author.meta.id === user.id 
                                                        ? 'text-white/70 hover:text-white' 
                                                        : 'text-gray-500 hover:text-gray-700'
                                                }`}
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {/* Input Form */}
            <form 
                onSubmit={async (e) => {
                    e.preventDefault();
                    if (!newNote.trim()) return;

                    try {
                        await taskService.addNote(task.id, newNote, token);
                        setNewNote('');
                        fetchNotes(); // Refresh the latest messages
                    } catch (error) {
                        showToast({
                            title: 'Error!',
                            content: error.message || 'Could not add note',
                            type: 'error'
                        });
                    }
                }} 
                className="p-4 border-t bg-white mt-auto min-h-0"
            >
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                        placeholder="Write a comment..."
                        className="flex-1 px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button 
                        type="submit"
                        className="px-4 py-2 text-sm text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TaskItem;