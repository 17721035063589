import React from 'react';
import CustomLineChart from '../../Charts/LineChart';
import CustomStackedBarChart from '../../Charts/StackedBarChart';
import CustomPieChart from '../../Charts/PieChart';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'; 

// Helper function to format dates in Russian
const formatRussianDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('ru', { month: 'long' });
  return `${day}-${month}`;
};

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "DailyStats");
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(dataBlob, 'DailyStats.xlsx');
};

const DailyStatsCharts = ({data}) => {
  const dailyStats = data && data.daily_stats ? data.daily_stats  : [];
  console.log('DailyStatsCharts', dailyStats, data);
  // Format dates in Russian
  const dataWithRussianDates = dailyStats.map((item, index) => ({
    ...item,
    date: formatRussianDate(item.date),
    count: index + 1,
  }));

  const lineChartConfig = {
    dataKeys: ['day_sum', 'day_purchase', 'day_profit', 'day_discount_sum', 'day_bonus_cashback', 'day_bonus_spent'],
    labels: ['Сумма', 'Себестоимость', 'Прибыль', 'Скидка', 'Бонусный кэшбэк', 'Бонусы потрачены'],
    colors: ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'],
  };

  const totalCount = Math.round(dailyStats.reduce((acc, item) => acc + item.day_count, 0));
  const totalSum = Math.round(dailyStats.reduce((acc, item) => acc + item.day_sum, 0));
  const totalPurchase = Math.round(dailyStats.reduce((acc, item) => acc + item.day_purchase, 0));
  const totalProfit = Math.round(dailyStats.reduce((acc, item) => acc + item.day_profit, 0));
  const totalDiscount = Math.round(dailyStats.reduce((acc, item) => acc + item.day_discount_sum, 0));
  const totalBonusCashback = Math.round(dailyStats.reduce((acc, item) => acc + item.day_bonus_cashback, 0));
  const totalBonusSpent = Math.round(dailyStats.reduce((acc, item) => acc + item.day_bonus_spent, 0));

  const averageSum = totalSum / totalCount;
  const averagePurchase = totalPurchase / totalCount;
  const averageProfit = totalProfit / totalCount;
  const averageDiscount = totalDiscount / totalCount;
  const averageBonusCashback = totalBonusCashback / totalCount;
  const averageBonusSpent = totalBonusSpent / totalCount;

  const barchartConfig = {
    dataKeys: ['day_purchase', 'day_profit', 'day_discount_sum'],
    labels: ['Себестоимость', 'Прибыль', 'Скидка'],
    colors: ['#8884d8', '#82ca9d', '#ffc658'],
  };

  const pieConfig = {
    colors: ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#a4de6c']
  };
  
  const pieData1 = [
    { name: 'Себестоимость', value: totalPurchase },
    { name: 'Прибыль', value: totalProfit },
  ];

  const pieData2 = [
    { name: 'Скидка', value: totalDiscount },
    { name: 'Бонусный кэшбэк', value: totalBonusCashback },
    { name: 'Бонусы потрачены', value: totalBonusSpent },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
        <h3 className="text-lg font-semibold">Общее количество</h3>
        <p className="text-2xl">{formatNumberWithCommas(totalCount)}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-blue-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Сумма</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalSum)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averageSum.toFixed(2))} сом</p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Себестоимость</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalPurchase)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averagePurchase.toFixed(2))} сом</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Прибыль</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalProfit)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averageProfit.toFixed(2))} сом</p>
          </div>
          <div className="bg-orange-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Скидка</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalDiscount)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averageDiscount.toFixed(2))} сом</p>
          </div>
          <div className="bg-purple-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Бонусный кэшбэк</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalBonusCashback)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averageBonusCashback.toFixed(2))} сом</p>
          </div>
          <div className="bg-red-100 p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold">Бонусы потрачены</h3>
            <p className="text-2xl">{formatNumberWithCommas(totalBonusSpent)} сом</p>
            <p className="text-sm">Среднее: {formatNumberWithCommas(averageBonusSpent.toFixed(2))} сом</p>
          </div>
        </div>


      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Line Chart - full width */}
        <div className=" bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold mb-4">По дням</h2>
          <CustomLineChart data={dataWithRussianDates} config={lineChartConfig} />
        </div>

        {/* Pie Chart */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold mb-4">Себестоимость и прибыль</h2>
          <CustomPieChart data={pieData1} config={pieConfig} />
        </div>


        {/* Stacked Bar Chart */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold mb-4">Себестоимость, прибыль и скидка</h2>
          <CustomStackedBarChart data={dataWithRussianDates} config={barchartConfig} />
        </div>

        {/* Pie Chart */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold mb-4">Бонусы и скидки</h2>
          <CustomPieChart data={pieData2} config={pieConfig} />
        </div>
      </div>

      {/* Data Table */}
      <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
        <h2 className="text-xl font-semibold mb-4">Таблица данных</h2>
        <button
          className="mb-4 bg-blue-500 text-white py-2 px-4 rounded"
          onClick={() => exportToExcel(dataWithRussianDates)}
        >
          Экспорт в Excel
        </button>
        <table className="min-w-full bg-white hover:table-hover">
          <thead>
            <tr>
              <th className="py-2">№</th>
              <th className="py-2">Дата</th>
              <th className="py-2">Сумма</th>
              <th className="py-2">Себестоимость</th>
              <th className="py-2">Прибыль</th>
              <th className="py-2">Скидка</th>
              <th className="py-2">Бонусный кэшбэк</th>
              <th className="py-2">Бонусы потрачены</th>
            </tr>
          </thead>
          <tbody>
            {dataWithRussianDates.map((item, index) => (
              <tr key={index} className="text-center hover:bg-gray-100">
                <td className="py-2">{item.count}</td>
                <td className="py-2">{item.date}</td>
                <td className="py-2">{formatNumberWithCommas(item.day_sum)} сом</td>
                <td className="py-2">{formatNumberWithCommas(item.day_purchase)} сом</td>
                <td className="py-2">{formatNumberWithCommas(item.day_profit)} сом</td>
                <td className="py-2">{formatNumberWithCommas(item.day_discount_sum)} сом</td>
                <td className="py-2">{formatNumberWithCommas(item.day_bonus_cashback)} сом</td>
                <td className="py-2">{formatNumberWithCommas(item.day_bonus_spent)} сом</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DailyStatsCharts;