import React, { useState, useEffect } from 'react';
import { ProjectItem } from 'components';
import { projectGroupService } from 'services';


const ProjectsBoard = ({ projectGroup }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const groupProjects = await projectGroupService.getProjects(projectGroup.id);
        setProjects(groupProjects.response.rows || []);
      } catch (error) {
        showToast({
          title: 'Error!',
          content: error.message || 'Could not fetch projects',
          type: 'error'
        });
      }
    };

    fetchProjects();
  }, [projectGroup.id]);

  return (
    <div className="h-[calc(100vh-2rem)] overflow-hidden">
      <div className="flex overflow-x-auto h-full pb-4 scrollbar-thin">
        {projects.map(project => (
          <div key={project.id} className="flex-none px-3 first:pl-4 last:pr-4 w-80">
            <ProjectItem project={project} />
          </div>
        ))}
      </div>
    </div>
  );
};


export default ProjectsBoard;