import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertCircle, CheckCircle2, Info, XCircle } from 'lucide-react';

const Toast = forwardRef(({ position = { X: 'Right', Y: 'Top' } }, ref) => {
  const [toasts, setToasts] = useState([]);

  // Calculate position classes based on props
  const getPositionClasses = () => {
    const positions = {
      Right: {
        Top: 'top-4 right-4',
        Bottom: 'bottom-4 right-4'
      },
      Left: {
        Top: 'top-4 left-4',
        Bottom: 'bottom-4 left-4'
      },
      Center: {
        Top: 'top-4 left-1/2 -translate-x-1/2',
        Bottom: 'bottom-4 left-1/2 -translate-x-1/2'
      }
    };
    return positions[position.X]?.[position.Y] || positions.Right.Top;
  };

  // Get icon based on toast type
  const getIcon = (type) => {
    const icons = {
      success: <CheckCircle2 className="w-6 h-6 text-green-500" />,
      warning: <AlertCircle className="w-6 h-6 text-yellow-500" />,
      error: <XCircle className="w-6 h-6 text-red-500" />,
      info: <Info className="w-6 h-6 text-blue-500" />
    };
    return icons[type] || icons.info;
  };

  // Get background color based on toast type
  const getBackgroundColor = (type) => {
    const colors = {
      success: 'bg-green-50 border-green-200',
      warning: 'bg-yellow-50 border-yellow-200',
      error: 'bg-red-50 border-red-200',
      info: 'bg-blue-50 border-blue-200'
    };
    return colors[type] || colors.info;
  };

  useImperativeHandle(ref, () => ({
    show: (options) => {
      const id = Date.now();
      const newToast = {
        id,
        title: options.title || '',
        content: options.content || '',
        type: options.type || 'info',
        duration: options.duration || 3000,
        ...options
      };
      
      setToasts(prev => [...prev, newToast]);

      // Auto-hide toast after duration
      setTimeout(() => {
        setToasts(prev => prev.filter(toast => toast.id !== id));
      }, newToast.duration);
    },
    hide: (toastId) => {
      if (toastId === 'All') {
        setToasts([]);
      } else {
        setToasts(prev => prev.filter(toast => toast.id !== toastId));
      }
    }
  }));

  return (
    <div className={`fixed z-50 flex flex-col gap-2 min-w-max max-w-sm ${getPositionClasses()}`}>
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`flex items-start p-4 rounded-lg shadow-lg border ${getBackgroundColor(toast.type)} transition-all duration-300 ease-in-out`}
        >
          <div className="flex-shrink-0">{getIcon(toast.type)}</div>
          <div className="ml-3 flex-1">
            {toast.title && (
              <h3 className="text-sm font-medium text-gray-900">{toast.title}</h3>
            )}
            {toast.content && (
              <p className="mt-1 text-sm text-gray-500">{toast.content}</p>
            )}
          </div>
          <button
            onClick={() => setToasts(prev => prev.filter(t => t.id !== toast.id))}
            className="ml-4 flex-shrink-0 text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <XCircle className="w-5 h-5" />
          </button>
        </div>
      ))}
    </div>
  );
});

export default Toast;