import { BASE_URL } from 'data/config';

class ApiClient {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    async request(endpoint, options = {}) {
        const {
            method = 'GET',
            token = 'token',
            data,
            queryString = '',
        } = options;

        console.log('request', endpoint, options);

        const url = `${this.baseURL}${endpoint}${queryString}`;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };

        const config = {
            method,
            headers,
            ...(data && { body: JSON.stringify(data) }),
        };

        const response = await fetch(url, config);

        // if (!response.ok) {
        //     let errorMessage = `Error: ${endpoint} with status ${response.status} (${response.statusText})`;
        //     try {
        //         const errorData = await response.json();
        //         if (errorData.message) {
        //             errorMessage = errorData.message;
        //         }
        //     } catch (parseError) {
        //         console.error('Failed to parse error response:', parseError);
        //     }
        //     throw new Error(errorMessage);
        // }

        if (options.isBlob) {
            return response.blob();
        }

        const responseData = await response.json();
        return responseData;
    }

    // Utility methods remain the same
    async get(endpoint, queryString = '', token = 'token') {
        return this.request(endpoint, { queryString, token });
    }

    async post(endpoint, data, token = 'token') {
        return this.request(endpoint, { method: 'POST', data, token });
    }

    async patch(endpoint, data, token = 'token') {
        return this.request(endpoint, { method: 'PATCH', data, token });
    }

    async put(endpoint, data, token = 'token') {
        return this.request(endpoint, { method: 'PUT', data, token });
    }

    async delete(endpoint, data, token = 'token') {
        return this.request(endpoint, { method: 'DELETE', data, token });
    }

    async export(endpoint, queryString = '', token = 'token') {
        const blob = await this.request(endpoint, {
            queryString,
            token,
            isBlob: true
        });

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'export.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    }
}

export const apiClient = new ApiClient(BASE_URL);

// base-service.js
export class BaseApiService {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    getList(queryString = '', token = 'token') {
        return apiClient.get(this.endpoint, queryString, token);
    }

    getById(id, token = 'token') {
        return apiClient.get(`${this.endpoint}/${id}/`, '', token);
    }

    save(data, token) {
        if (data.id) {
            return this.update(data.id, data, token);
        }
        return this.create(data, token);
    }
    
    create(data, token = 'token') {
        return apiClient.post(`${this.endpoint}/`, data, token);
    }

    update(id, data, token = 'token') {
        return apiClient.put(`${this.endpoint}/${id}/`, data, token);
    }

    delete(id, token = 'token') {
        return apiClient.delete(`${this.endpoint}/${id}/`, token);
    }

    export(queryString = '', token = 'token') {
        return apiClient.export(this.endpoint, queryString, token);
    }

    crud(data, token = 'token') {
        if (data.id) {
            return this.update(data.id, data, token);
        }
        return this.create(data, token);
    }
}
