import React from 'react';
import { List, ProductGroupItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const ProductGroupsList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Имя',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          value={value}
          placeholder="Поиск по имени"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__gte',
      label: 'Создано после',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Создано до',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Обновлено после',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lte',
      label: 'Обновлено до',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Имя' },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/product-groups/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ProductGroupItem}
    />
  );
};

export default ProductGroupsList;
