import React, { useMemo, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent, Checkbox, Select } from 'components';
import CustomPieChart from '../../Charts/PieChart';
import * as XLSX from 'xlsx'; 


const DailyConsultantStats = ({ data }) => {
  const [visibleColumns, setVisibleColumns] = useState({
    count: true,
    sum: true,
    profit: true,
    discount: true,
    bonus: true
  });
  const [selectedConsultants, setSelectedConsultants] = useState(new Set());
  const [pieChartMetric, setPieChartMetric] = useState('total_sum');

  const dates = useMemo(() => {
    const uniqueDates = new Set();
    data.forEach(consultantData => {
      consultantData.daily_stats.forEach(stat => {
        uniqueDates.add(stat.date);
      });
    });
    return Array.from(uniqueDates).sort();
  }, [data]);

  const dailyStatsMap = useMemo(() => {
    const map = {};
    data.forEach(consultantData => {
      map[consultantData.consultant.meta.id] = {};
      consultantData.daily_stats.forEach(stat => {
        map[consultantData.consultant.meta.id][stat.date] = stat;
      });
    });
    return map;
  }, [data]);

  const pieChartData = useMemo(() => {
    return data
      .filter(d => selectedConsultants.has(d.consultant.meta.id))
      .map(d => ({
        name: d.consultant.meta.name,
        value: d.total_stats[pieChartMetric]
      }));
  }, [selectedConsultants, data, pieChartMetric]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('ru-RU', { day: '2-digit', month: 'short' });
  };

  const handleConsultantSelect = (consultantId) => {
    const newSelected = new Set(selectedConsultants);
    if (newSelected.has(consultantId)) {
      newSelected.delete(consultantId);
    } else {
      newSelected.add(consultantId);
    }
    setSelectedConsultants(newSelected);
  };

  const columns = [
    { key: 'count', label: 'Кол-во', field: 'day_count', totalField: 'total_count' },
    { key: 'sum', label: 'Сумма', field: 'day_sum', totalField: 'total_sum' },
    { key: 'profit', label: 'Прибыль', field: 'day_profit', totalField: 'total_profit' },
    { key: 'discount', label: 'Скидка', field: 'day_discount_sum', totalField: 'total_discount_sum' },
    { key: 'bonus', label: 'Бонусы', field: 'day_bonus_spent', totalField: 'total_bonus_spent' }
  ];

  const visibleColumnsList = columns.filter(col => visibleColumns[col.key]);
  
  const exportToExcel = () => {
    // Create first row with consultant, total, and dates
    const firstRow = ['Консультант', 'Итого'];
    const secondRow = ['', ...visibleColumnsList.map(col => col.label)];
    
    // Add each date to the first row, and corresponding column labels to second row
    dates.forEach(date => {
      // Add column labels under this date
      visibleColumnsList.forEach(col => {
        firstRow.push(formatDate(date)); 
        secondRow.push(col.label);
      });
    });
  
    // Create data rows
    const dataRows = data.map(consultantData => {
      return [
        consultantData.consultant.meta.name,
        // Total stats
        ...visibleColumnsList.map(col => 
          consultantData.total_stats[col.totalField]
        ),
        // Daily stats for each date
        ...dates.flatMap(date => {
          const stats = dailyStatsMap[consultantData.consultant.meta.id][date] || {};
          return visibleColumnsList.map(col => stats[col.field]);
        })
      ];
    });
  
    // Create worksheet from all rows
    const worksheet = XLSX.utils.aoa_to_sheet([firstRow, secondRow, ...dataRows]);
  
    // Setup merges
    worksheet['!merges'] = [];
    
    // Merge "Total" header
    worksheet['!merges'].push({
      s: { r: 0, c: 1 },
      e: { r: 0, c: visibleColumnsList.length }
    });
  
    // Calculate and add merge regions for each date
    let currentCol = visibleColumnsList.length + 1;  // Start after total columns
    dates.forEach((date, index) => {
      worksheet['!merges'].push({
        s: { r: 0, c: currentCol },
        e: { r: 0, c: currentCol + visibleColumnsList.length - 1 }
      });
      currentCol += visibleColumnsList.length;
    });
  
    // Create and save workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ConsultantStats");
    XLSX.writeFile(workbook, "ConsultantStats.xlsx");
  };
  return (
    <Card className="space-y-6">
      <CardHeader>
        <CardTitle>Детальная статистика по консультантам</CardTitle>
      </CardHeader>
      <CardContent>
        {/* Pie Chart for Selected Consultants */}
        {selectedConsultants.size > 0 && (
          <div className="mb-12">
            <div className="flex items-center gap-4 mb-4">
              <h3 className="text-lg font-semibold">Статистика выбранных консультантов по</h3>
              <Select
                value={pieChartMetric}
                onValueChange={setPieChartMetric}
                className="w-48"
              >
                {columns.map(col => (
                  <Select.Option key={col.key} value={col.totalField}>
                    {col.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="h-96">
              <CustomPieChart 
                data={pieChartData}
                config={{ colors: ['#8884d8', '#82ca9d', '#ffc658', '#a4de6c', '#ff8042'] }}
              />
            </div>
          </div>
        )}

        {/* Table Control */}
        <div className="mb-4 mt-12 flex justify-between items-center">
            <div className="flex flex-wrap gap-4">
            {columns.map(col => (
                <div key={col.key} className="flex items-center space-x-2">
            <Checkbox
                id={`toggle-${col.key}`}
                checked={visibleColumns[col.key]}
                onChange={(checked) => 
                setVisibleColumns(prev => ({ ...prev, [col.key]: checked }))
                }
            />
            <label htmlFor={`toggle-${col.key}`} className="text-sm">
                {col.label}
            </label>
                </div>
            ))}
            </div>
            <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            onClick={exportToExcel}
            >
            Экспорт в Excel
            </button>
        </div>

            
        {/* Stats Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-200">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="px-4 py-2 border border-gray-200 text-left" rowSpan={2}>
                  Консультант
                </th>
                <th className="px-4 py-2 text-center font-semibold border border-gray-200" 
                    colSpan={visibleColumnsList.length}>
                  Итого
                </th>
                {dates.map((date) => (
                  <th key={date} 
                      className="px-4 py-2 text-center font-semibold border border-gray-200"
                      colSpan={visibleColumnsList.length}>
                    {formatDate(date)}
                  </th>
                ))}
              </tr>
              <tr>
                {visibleColumnsList.map((col) => (
                  <th key={`total-${col.key}`} 
                      className="px-2 py-1 text-sm font-medium text-gray-600 border border-gray-200">
                    {col.label}
                  </th>
                ))}
                {dates.map((date) => (
                  <React.Fragment key={`metrics-${date}`}>
                    {visibleColumnsList.map((col) => (
                      <th key={`${date}-${col.key}`} 
                          className="px-2 py-1 text-sm font-medium text-gray-600 border border-gray-200">
                        {col.label}
                      </th>
                    ))}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((consultantData) => (
                <tr key={consultantData.consultant.meta.id} 
                    className="hover:bg-gray-50">
                  <td className="px-4 py-2 font-medium border border-gray-200">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        checked={selectedConsultants.has(consultantData.consultant.meta.id)}
                        onChange={() => handleConsultantSelect(consultantData.consultant.meta.id)}
                      />
                      {consultantData.consultant.meta.name}
                    </div>
                  </td>
                  {visibleColumnsList.map((col) => (
                    <td key={`total-${col.key}`} 
                        className="px-2 py-1 text-center border border-gray-200">
                      {consultantData.total_stats[col.totalField]?.toLocaleString() || '—'}
                    </td>
                  ))}
                  {dates.map((date) => {
                    const stats = dailyStatsMap[consultantData.consultant.meta.id][date] || {};
                    return (
                      <React.Fragment key={`${consultantData.consultant.meta.id}-${date}`}>
                        {visibleColumnsList.map((col) => (
                          <td key={`${date}-${col.key}`}
                              className="px-2 py-1 text-center border border-gray-200">
                            {stats[col.field] ? stats[col.field].toLocaleString() : '—'}
                          </td>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};

export default DailyConsultantStats;