import React from 'react';
import { ItemSelector, List, OrderItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');
const  statsToRussian = (status) => {
  switch(status){
    case 'all': return 'Все';
    case 'cart': return 'В корзине';
    case 'ordered': return 'Оформлен';
    case 'preparing': return 'Сборка';
    case 'ready': return 'Готов';
    case 'delivering': return 'Доставка';
    case 'completed': return 'Завершен';
    case 'canceled': return 'Отменен';
    default: return 'Статус неизвестен';
  };

};

const OrdersList = (props) => {
  const filterOptions = [
    {
      key: 'title',
      label: 'Заголовок',
      field: (props) => (
        <input 
          type="text" 
          placeholder="Заголовок" 
          value={props.value} 
          className={props.className} 
          onChange={(e) => props.onChange(e.target.value)} 
        />
      ),
      initialValue: '',
    },
    {
      key: 'status',
      label: 'Статус',
      field: ({value, onChange, className}) => (
        <ItemSelector
          options={['cart', 'ordered', 'preparing', 'ready', 'delivering', 'completed', 'canceled'].map((status) => ({id: status, name: statsToRussian(status)}))}
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите статус"
          searchPlaceholder="Поиск статуса..."
        />
      ),
      initialValue: ['ordered', 'preparing', 'ready', 'delivering'].map((status) => ({id: status, name: statsToRussian(status)})),
    },
    {
      key: 'client',
      label: 'Клиенты',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/parties/admin-api/clients"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите клиента"
          searchPlaceholder="Поиск клиента..."
          noItemsFoundText="Клиенты не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.client ? [props.client] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Название', component: (item) => <>{item.meta?.name}</> },
    { key: 'description', label: 'Описание' },
    { key: 'status', label: 'Статус', component: (item) => <>{statsToRussian(item.status)}</> },
    { key: 'client', label: 'Клиент', component: (item) => <>{item.client.meta.name}</> },
    { key: 'store', label: 'Магазин', component: (item) => <>{item.store?.meta.name}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/crm/admin-api/orders/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={OrderItem}
    />
  );
};

export default OrdersList;