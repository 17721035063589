import React from 'react';
import { List, NotificationTemplateItem } from 'components';

const formatDate = (date) => new Date(date).toLocaleDateString();

const NotificationTemplatesList = (props) => {
  const filterOptions = [
    {
      key: 'title',
      label: 'Название',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          placeholder="Введите название"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'is_active',
      label: 'Активный',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Все</option>
          <option value="true">Активный</option>
          <option value="false">Неактивный</option>
        </select>
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'title', label: 'Название' },
    { key: 'content', label: 'Содержание', component: (item) => <>{item.content}</> },
    { key: 'notifications__count', label: 'Количество', component: (item) => <>{item.notifications && item.notifications.meta.size}</> },
    { key: 'is_active', label: 'Активный', component: (item) => <>{item.is_active ? 'Да' : 'Нет'}</> },
    { key: 'is_sms', label: 'СМС', component: (item) => <>{item.is_sms ? 'Да' : 'Нет'}</> },
    {
      key: 'date',
      label: 'Дата',
      component: (item) => <>{formatDate(item.date)}</>,
    },
    {
      key: 'created_at',
      label: 'Создано',
      component: (item) => <>{formatDate(item.created_at)}</>,
    },
    {
      key: 'updated_at',
      label: 'Обновлено',
      component: (item) => <>{formatDate(item.updated_at)}</>,
    },
  ];

  return (
    <List
      targetUrl="/crm/admin-api/notification-templates"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={NotificationTemplateItem}
    />
  );
};

export default NotificationTemplatesList;
