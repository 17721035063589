import React, { useState } from 'react';
import { ItemSelector } from 'components';
import { Eye, EyeOff } from 'lucide-react';
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';
import { employeeService } from 'services';

const EmployeeForm = ({ employee }) => {
  const { closeModal } = useLayout();
  const { showToast } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [employeeData, setEmployeeData] = useState(employee || {});
  const [stores, setStores] = useState(employee ? employee?.stores : []);
  const [groups, setGroups] = useState(employee ? [employee?.group] : []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        ...employeeData,
        store_ids: stores.map((store) => store.id),
        group_id: groups[0]?.id,
      };

      console.log('data', data);
      const response = await employeeService.save(data);
      
      if (response.status === 1) {
        setErrors(response.response || {});
        showToast({
          title: 'Ошибка',
          content: response.message || 'Ошибка валидации',
          type: 'error',
        });
        return;
      }

      showToast({
        title: 'Успех!',
        content: employee ? 'Сотрудник успешно обновлен!' : 'Сотрудник успешно создан!',
        type: 'success',
      });
      closeModal();
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message || 'Что-то пошло не так!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEmployeeData((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const inputClassName = (error) => `
    mt-1 block w-full rounded-md
    ${error 
      ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
      : 'border-blue-500 ring-gray-100 focus:ring-blue-500'
    }
    transition-colors duration-200
    p-2.5
    ring-2 focus:ring-opacity-50 
    outline-none
  `;

  return (
    <div className="p-8 bg-white">
      <h2 className="text-2xl font-semibold mb-8 text-gray-800">
        {employee ? 'Редактировать сотрудника' : 'Создать сотрудника'}
      </h2>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Имя пользователя <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={inputClassName(errors.username)}
            value={employeeData.username || ''}
            onChange={(e) => handleInputChange('username', e.target.value)}
          />
          {errors.username && (
            <p className="mt-1.5 text-sm text-red-500">{errors.username}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Имя <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={inputClassName(errors.first_name)}
            value={employeeData.first_name || ''}
            onChange={(e) => handleInputChange('first_name', e.target.value)}
          />
          {errors.first_name && (
            <p className="mt-1.5 text-sm text-red-500">{errors.first_name}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Фамилия <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={inputClassName(errors.last_name)}
            value={employeeData.last_name || ''}
            onChange={(e) => handleInputChange('last_name', e.target.value)}
          />
          {errors.last_name && (
            <p className="mt-1.5 text-sm text-red-500">{errors.last_name}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Отдел <span className="text-red-500">*</span>
          </label>
          <ItemSelector
            targetUrl="/admin-api/employee-groups"
            onSelect={(item) => setGroups([item])}
            onRemove={(id) => setGroups([])}
            selectedItems={groups}
            placeholder="Выберите отдел"
            searchPlaceholder="Поиск отделов..."
            noItemsFoundText="Отделы не найдены"
            isMultiSelect={false}
          />
          {errors.group_id && (
            <p className="mt-1.5 text-sm text-red-500">{errors.group_id }</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Магазины
          </label>
          <ItemSelector
            targetUrl="/admin-api/stores"
            onSelect={(item) => setStores((prev) => [...prev, item])}
            onRemove={(id) => setStores((prev) => prev.filter((item) => item.id !== id))}
            selectedItems={stores}
            placeholder="Выберите магазины"
            searchPlaceholder="Поиск магазинов..."
            noItemsFoundText="Магазины не найдены"
            isMultiSelect={true}
          />
          {errors.store_ids && (
            <p className="mt-1.5 text-sm text-red-500">{errors.store_ids}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Электронная почта
          </label>
          <input
            type="email"
            className={inputClassName(errors.email)}
            value={employeeData.email || ''}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
          {errors.email && (
            <p className="mt-1.5 text-sm text-red-500">{errors.email}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Телефон <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            className={inputClassName(errors.phone)}
            value={employeeData.phone || ''}
            onChange={(e) => handleInputChange('phone', e.target.value)}
          />
          {errors.phone && (
            <p className="mt-1.5 text-sm text-red-500">{errors.phone}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Пароль {!employee && <span className="text-red-500">*</span>}
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`${inputClassName(errors.password)} pr-10`}
              value={employeeData.password || ''}
              onChange={(e) => handleInputChange('password', e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 transition-colors"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          {errors.password && (
            <p className="mt-1.5 text-sm text-red-500">{errors.password}</p>
          )}
        </div>

        <div className="flex justify-end space-x-4 pt-4">
          <button
            type="button"
            onClick={closeModal}
            className="px-6 py-2.5 border border-gray-200 rounded-md text-gray-700 hover:bg-gray-50 transition-colors duration-200"
          >
            Отмена
          </button>
          <button
            type="button"
            onClick={handleSave}
            disabled={loading}
            className={`px-6 py-2.5 rounded-md text-white transition-colors duration-200 ${
              loading 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
            }`}
          >
            {loading ? 'Сохранение...' : 'Сохранить'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeForm;