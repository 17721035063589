import { useState, useEffect } from 'react';
import { Suspense } from 'react';
import { Modal, SectionsList, SectionItem } from "../../../components";
import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../data/config';

async function loadSectionsList(is_archived = false) {
  const token = "token";
  let url = BASE_URL + "/dashboard/admin-api/sections";
  if (is_archived) {
    url += "?is_archived=" + is_archived;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to load sections");
  } else {
    const resData = await response.json();
    console.log('resData', resData.response);
    return resData.response.rows;
  }
}

function Sections({ archived = false }) {
  let title = "Активные страницы";
  const [selectedSection, setSelectedSection] = useState(null);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Modal, setModal, showToast } = useStateContext();

  if (archived) {
    title = "Архивные страницы";
  }

  useEffect(() => {
    async function fetchSections() {
      try {
        setLoading(true);
        const sectionsData = await loadSectionsList(archived);
        console.log('fetched sections', sectionsData);
        setSections(sectionsData);
      } catch (error) {
        showToast({ 
          title: 'Ошибка!', 
          content: error.message, 
          cssClass: 'e-toast-danger', 
          icon: 'e-error toast-icons' 
        });
      } finally {
        setLoading(false);
      }
    }
    fetchSections();
  }, [showToast, archived]);

  return (
    <>
      {loading ? (
        <p className="flex flex-wrap">Loading...</p>
      ) : (
        <SectionsList sections={sections} title={title} selectHandler={(section) => {
          console.log("selectHandler");
          setSelectedSection(section);
          setModal(true);
        }} />
      )}
      {Modal && selectedSection && (
        <Modal title={"Cотрудник: " + selectedSection.name} afterClose={() => setSelectedSection(null)}>
          <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
            <SectionItem section={selectedSection} />
          </Suspense>
          <h1 className="flex md:flex-row flex-col">Here will be related statistics and more info...</h1>
        </Modal>
      )}
    </>
  );
}

export default Sections;
