// !Utils:
export { default as UnderConstruction } from "./UnderConstruction";
export { default as RootLayout } from "./Root";
export { default as ErrorPage } from "./Error";
export { default as AuthenticationPage } from "./Authentication";


// !Stats
export { default as DocStats } from "./Stats/DocStats";
export { default as StoreStats } from "./Stats/StoreStats";
export { default as ConsultantStats } from "./Stats/ConsultantStats";




// !Tests
export  { default as  TestRootLayout } from "./Dev/Test/TestRoot";
import { default as Test1Page } from "./Dev/Test/Test1";
import { default as Test2Page } from "./Dev/Test/Test2";
import { default as Test3Page } from "./Dev/Test/Test3";
import { default as Test4Page } from "./Dev/Test/Test4";
import { default as Test5Page } from "./Dev/Test/Test5";
import { default as Test6Page } from "./Dev/Test/Test6";
import { default as Test7Page } from "./Dev/Test/Test7";
import { default as Test8Page } from "./Dev/Test/Test8";
import { default as Test9Page } from "./Dev/Test/Test9";
import { default as Test10Page } from "./Dev/Test/Test10";
import { default as Test11Page } from "./Dev/Test/Test11";
import { default as Test12Page } from "./Dev/Test/Test12";


export const TestPages = [
    Test1Page,
    Test2Page,
    Test3Page,
    Test4Page,
    Test5Page,
    Test6Page,
    Test7Page,
    Test8Page,
    Test9Page,
    Test10Page,
    Test11Page,
    Test12Page,
]

// !Docs
export { default as DocsRootLayout } from "./Docs/DocsRoot";
export { default as Docs } from "./Docs/Docs";
export { default as SetConsultants } from "./Docs/SetConsultants";
export { default as ViewDoc } from "./Docs/ViewDoc";


// !Company

export { default as Employees } from "./Company/Employees/Employees";
export { default as Consultants } from "./Company/Employees/Consultants";

export { default as EmployeeGroups } from "./Company/EmployeeGroups/EmployeeGroups";

export { default as Stores } from "./Company/Stores/Stores";


// !Consultants
export { default as ConsultantsRootLayout } from "./Consultants/ConsultantsRoot";
export { default as ConsultantReport } from "./Consultants/ConsultantReport";

// export { default as Consultants } from "./Consultants/Consultants";
// export { default as AddConsultant } from "./Consultants/AddConsultant";
// export { default as ViewConsultant } from "./Consultants/ViewConsultant";
// export { default as EditConsultant } from "./Consultants/EditConsultant";
// export { loadConsultantsList,  consultantDetailLoader } from './Consultants/Services';

// !Dashboard
export { default as DevRootLayout } from "./Dev/DevRoot";
export { default as SectionRootLayout } from "./Dev/sections/SectionRoot";
export { default as Sections } from "./Dev/sections/Sections";
export { default as AddSection } from "./Dev/sections/AddSection";
export { default as EditSection } from "./Dev/sections/EditSection";
export { default as ViewSection } from "./Dev/sections/ViewSection";

// !Products
export { default as Products } from './Products/Products';
export { default as Categories } from './Products/Categories/Categories';
export { default as ProductGroups } from './Products/ProductGroups/ProductGroups';

// !Projects
export { default as Projects } from './Projects/Projects';

// !Clients
export { default as Clients } from './Parties/Clients';
export { default as EditClient } from './Parties/EditClient';
export { default as ClientGroups } from './Parties/ClientGroups/ClientGroups';
// !Suppliers
export { default as Suppliers } from './Parties/Suppliers';
export { default as SupplierGroups } from './Parties/SupplierGroups/SupplierGroups';


// !CRM
export { default as CRMStats } from './CRM/CRMStats';
export { default as CRMRootLayout } from './CRM/CRMRoot';
export { default as CrmMap } from "./CRM/CrmMap";

// !Orders
export { default as Orders } from "./CRM/Orders/Orders";
export { default as OrderDetail } from "./CRM/Orders/OrderDetail";


// !Notifications
// export { default as NotificationRootLayout } from "./CRM/Notifications/NotificationRoot";
// export { default as Tags } from "./CRM/Notifications/Tags";
export { default as Notifications } from "./CRM/Notifications/Notifications";
// export { default as AddNotification } from "./CRM/Notifications/AddNotification";
// export { default as EditNotification } from "./CRM/Notifications/EditNotification";
// export { default as NotificationDetail } from "./CRM/Notifications/NotificationDetail";
export { default as NotificationReport } from "./CRM/Notifications/NotificationReport";

export { default as NotificationTemplates } from "./CRM/Notifications/NotificationTemplates";

// !App
export { default as AppRootLayout } from "./App/AppRoot";
export { default as QA } from "./App/QA";
export { default as ContactLinks } from "./App/ContactLinks";

// !Assortments
export { default as AssortmentsRootLayout } from "./App/assortments/AssortmentRoot";
export { default as Assortments } from "./App/assortments/Assortments";
export { default as AddAssortment } from "./App/assortments/AddAssortment";
export { default as ViewAssortment } from "./App/assortments/ViewAssortment";
export { default as EditAssortment } from "./App/assortments/EditAssortment";

// !Banners
export {default as BannersRootLayout} from "./App/banners/BannersRoot";
export {default as Banners} from "./App/banners/Banners";
export {default as AddBanner} from "./App/banners/AddBanner";
export {default as ViewBanner} from "./App/banners/ViewBanner";
export {default as EditBanner} from "./App/banners/EditBanner";

// !Brands
export {default as BrandsRootLayout} from "./App/brands/BrandsRoot";
export {default as Brands} from "./App/brands/Brands";
export {default as AddBrand} from "./App/brands/AddBrand";
export {default as ViewBrand} from "./App/brands/ViewBrand";
export {default as EditBrand} from "./App/brands/EditBrand";

// !Categories
export { default as ListingsRootLayout } from "./App/listings/ListingsRoot";
export { default as Listings } from "./App/listings/Listings";
export { default as AddListing } from "./App/listings/AddListing";
export { default as ViewListing } from "./App/listings/ViewListing";
export { default as EditListing } from "./App/listings/EditListing";

// !Categories
export { default as PostsRootLayout } from "./App/posts/PostsRoot";
export { default as Posts } from "./App/posts/Posts";
export { default as AddPost } from "./App/posts/AddPost";
export { default as ViewPost } from "./App/posts/ViewPost";
export { default as EditPost } from "./App/posts/EditPost";






// !POS

export { default as Shifts } from "./Pos/Shifts/Shifts";


// ! Templates
export { default as Dashboard } from "./Dashboard";
