import React from 'react';
import { List, CategoryItem, ItemSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const CategoriesList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Имя',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          value={value}
          placeholder="Поиск по имени"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'productgroup',
      label: 'Группа товаров',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          targetUrl="/products/admin-api/product-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите группу товаров"
          searchPlaceholder="Поиск группы товаров..."
          noItemsFoundText="Группы товаров не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.productGroup ? [props.productGroup] : [],
    },
    {
      key: 'created__gte',
      label: 'Создано после',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Создано до',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Обновлено после',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lte',
      label: 'Обновлено до',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Имя' },
    { 
      key: 'products', 
      label: 'Количество товаров', 
      component: (item) => <>{item.products?.meta?.size || 0}</> 
    },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/categories/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={CategoryItem}
    />
  );
};

export default CategoriesList;
