import React, { useState } from 'react';
import { Phone, SquarePen } from 'lucide-react';
import { ProductsList, CategoriesList, Tabs, Tab, ProductGroupForm  } from "components";
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';

const ProductGroupItem = ({ item }) => {
  const { openModal } = useLayout();
  const { currentColor } = useStateContext();

  const handleEditProductGroup = () => {
      openModal({
        component: <ProductGroupForm productGroup={item}/>,
        title: "Изменить группу товаров",
      });
    };


  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-xl font-semibold mb-1">{item.name}</h1> 
          <p className="text-gray-500 text-xs">Создан {item.created}</p> 
        </div>
        <div className="text-right">
          <button className=" text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleEditProductGroup()}>
            <SquarePen size={16} className="mr-1" /> 
          </button>
        </div>
      </div>

      {/* Contacts Section */}
      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">КОНТАКТЫ</h2> 
        </div>
        <div className="p-4">
          <div className="flex items-center gap-2">
            <Phone size={16} className="text-gray-500" />
            <a href={`tel:${item.phone}`} className="text-blue-600 text-sm"> 
              {item.phone}
            </a>
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">СТАТИСТИКА</h2> 
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Кол-во продаж</span> 
                <span className="text-sm">{item.stats?.count}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Себестоимость продаж</span> 
                <span className="text-sm">{item.stats?.purchase}</span> 
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Кол-во товаров</span> 
                <span className="text-sm">{item.stats?.quantity}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Прибыль продаж</span> 
                <span className="text-sm">{item.stats?.profit}</span> 
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Operations Section */}
      <div className="bg-white rounded-lg shadow-sm border">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">Товары и категории</h2> 
        </div>
        <Tabs initialTab="products">
          <Tab key="products" label="Товары">
            <ProductsList productGroup={item}/>
          </Tab>
          <Tab key="categories" label="Категории">
            <CategoriesList productGroup={item}/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ProductGroupItem;