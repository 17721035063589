import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useLayout } from 'contexts/LayoutContext';

const CenterModal = ({ children, confirmCloseText, title }) => {
    const { closeModal } = useLayout();
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        setIsOpen(true);
    }, []);

    const closeModalHandler = () => {
        if(confirmCloseText){
            if(!window.confirm(confirmCloseText)){
                return;
            }
        }
        setIsOpen(false);
        setTimeout(() => {
            closeModal();
        }, 300); // Match transition duration
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                closeModalHandler();
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return (
        <div 
            className="fixed inset-0 z-[9999] bg-black/20 flex items-center justify-center"
            onClick={closeModalHandler}
        >
            <div 
                className={`w-full max-w-2xl bg-white dark:bg-[#484B52] rounded-lg shadow-xl transform transition-all duration-300 ease-in-out ${
                    isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
                }`}
                onClick={(e) => e.stopPropagation()}
                style={{ 
                    maxHeight: '90vh',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#d4d4d4 #f3f4f6'
                }}
            >
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold">{title}</h3>
                    <button
                        onClick={closeModalHandler}
                        className="rounded-full p-2 text-xl text-gray-400 hover:text-gray-600"
                        aria-label="close"
                    >
                        <MdOutlineCancel />
                    </button>
                </div>
                <div className="p-4 overflow-y-auto" style={{ maxHeight: 'calc(90vh - 4rem)' }}>
                    <div className="mx-auto bg-white">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CenterModal;