import React, { useState, useEffect } from 'react';
import { ProjectsBoard } from 'components';
import { projectGroupService } from 'services';
import { useStateContext } from 'contexts/ContextProvider';
import { useLayout } from 'contexts/LayoutContext';

const ProjectGroups = () => {
    const [projectGroups, setProjectGroups] = useState([]);
    const [selectedProjectGroup, setSelectedProjectGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const { token, showToast } = useStateContext();
    const { setSidebarContent  } = useLayout();

    useEffect(() => {
        const fetchProjectGroups = async () => {
            try {
                const response = await projectGroupService.getList('', token);
                setProjectGroups(response.response.rows || []);
            } catch (error) {
                showToast({
                    title: 'Error!',
                    content: error.message || 'Could not fetch projectGroups',
                    type: 'error'
                });
            } finally {
                setLoading(false);
            }
        };
        fetchProjectGroups();

    }, [token]);

    useEffect(() => {
        if (projectGroups.length > 0) {
            setSelectedProjectGroup(projectGroups[projectGroups.length - 1]);
            setSidebarContent(
                <div className="p-4">
                    <h1 className="text-2xl font-bold">Project Groups</h1>
                    <ul className="mt-4 space-y-2">
                        {projectGroups.map(group => (
                            <li key={group.id} 
                                className="cursor-pointer text-lg text-gray-600 hover:text-gray-800" 
                                onClick={() => setSelectedProjectGroup(group)}>
                                {group.name}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return () => setSidebarContent(null);
    }, [projectGroups, setSidebarContent]);

    if (loading) {
        return (
            <div className="p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {[1, 2, 3].map(i => (
                        <div key={i} className="bg-white rounded-lg shadow-md p-4">
                            <div className="animate-pulse">
                                <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                                <div className="space-y-3">
                                    <div className="h-20 bg-gray-100 rounded"></div>
                                    <div className="h-20 bg-gray-100 rounded"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="h-full bg-gray-50">
            <div className="p-4 bg-white border-b">
                <h1 className="text-2xl font-bold">Projects</h1>
            </div>
            { selectedProjectGroup && 
                <ProjectsBoard projectGroup={selectedProjectGroup} />
            }
        </div>
    );
};

export default ProjectGroups;