import React, { useEffect, useState } from 'react';
import { useStateContext } from 'contexts/ContextProvider';

const Filter = ({
    filterOptions,
    fetchItems,
    exportItems,
  }) => {
    const [searchValue, setSearchValue] = useState('');
    const [isFilterVisible, setIsFilterVisible] = useState(true);
    const [filters, setFilters] = useState(
        filterOptions.reduce((acc, option) => {
          acc[option.key] = option.initialValue;
          return acc;
        }, {})
      );
    const { currentColor } = useStateContext();
    
    useEffect(() => {
        fetchItems(searchValue, filters);
    }, []);
      
    const handleFilterChange = (key, value) => {
        console.log('handleFilterChange', key, value);
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const clearFilters = () => {
        setFilters(
        filterOptions.reduce((acc, option) => {
            acc[option.key] = option.initialValue;
            return acc;
        }, {})
        );
        fetchItems(searchValue, filters);
    };
    return (
        <>
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-2 p-2 bg-white border-b">
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 w-full sm:w-auto">
              <button
                onClick={() => setIsFilterVisible(!isFilterVisible)}
                className={`w-full sm:w-auto px-3 py-1.5 border rounded ${
                  isFilterVisible ? 'bg-gray-100' : 'bg-white'
                } hover:bg-gray-50`}
              >
                Фильтр
              </button>
              <input
                type="text"
                placeholder="Поиск..."
                className="w-full sm:w-auto px-3 py-1.5 border rounded"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) =>
                  (e.key === 'Enter' || e.target.value == '') &&
                  fetchItems(searchValue, filters)
                }
              />
            </div>
            <div className="flex items-center gap-2 w-full sm:w-auto">
              {exportItems && (
                <button
                onClick={exportItems}
                className="w-full sm:w-auto px-4 py-2 text-white rounded"
                style={{ backgroundColor: currentColor }}
              >
                Экспорт
              </button>
            )}
            </div>
          </div>
          {isFilterVisible && (
            <div className="w-full bg-gray-100 p-4 space-y-4 border rounded-md shadow-sm">
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  className="w-full sm:w-auto px-3 py-1.5 text-white rounded"
                  onClick={() => fetchItems(searchValue, filters)}
                  style={{ backgroundColor: currentColor }}
                >
                  Найти
                </button>
                <button
                  className="w-full sm:w-auto px-3 py-1.5 bg-white border rounded hover:bg-gray-50"
                  onClick={clearFilters}
                >
                  Очистить
                </button>
              </div>
      
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {filterOptions.map(({ key, label, field: FieldComponent }) => (
                  <div key={key} className="space-y-1">
                    <label className="text-sm text-gray-600">{label}</label>
                    <FieldComponent
                      value={filters[key]}
                      onChange={(value) => handleFilterChange(key, value)}
                      className="w-full h-8 px-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      );
  };

export default Filter;