import React, { useEffect } from 'react'
import { useStateContext } from 'contexts/ContextProvider';
import { orderService } from 'services';


const OrderStats = () => {
  const [stats, setStats] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const { showToast, token } = useStateContext();

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await orderService.getStats(token);
        setStats(response.response);
      } catch (error) {
        showToast({
          title: 'Ошибка',
          message: error.response.data.message || 'Не удалось загрузить статистику заказов',
          type: 'error',
        })
      } finally {
        setLoading(false);
      }
    }
    getStats();
  }, [token, showToast]);


  return (
    <div className="mt-4 grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-green-100 p-4 rounded-lg shadow-lg">
        <p className="text-green-800">Оформлен</p>
        <p className="font-bold text-lg">{stats.ordered}</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg shadow-lg">
        <p className="text-yellow-800">Сборка</p>
        <p className="font-bold text-lg">{stats.preparing}</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg shadow-lg">
        <p className="text-purple-800">Готов</p>
        <p className="font-bold text-lg">{stats.ready}</p>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg shadow-lg">
        <p className="text-blue-800">Доставка</p>
        <p className="font-bold text-lg">{stats.delivering}</p>
        </div>
    </div>
  )
}

export default OrderStats