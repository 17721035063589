import React, { useState, useRef, useEffect } from 'react';

const Select = ({ 
  value, 
  onValueChange, 
  children, 
  className = '' 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedOption = React.Children.toArray(children)
    .find(child => child.props.value === value);

  return (
    <div 
      ref={selectRef}
      className={`relative ${className}`}
    >
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-3 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <span className="block truncate">
          {selectedOption ? selectedOption.props.children : 'Select option'}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <ul className="py-1 overflow-auto text-base max-h-60">
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                onSelect: () => {
                  onValueChange(child.props.value);
                  setIsOpen(false);
                },
                isSelected: child.props.value === value
              });
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const Option = ({ 
  children, 
  onSelect, 
  isSelected 
}) => {
  return (
    <li
      onClick={onSelect}
      className={`px-3 py-2 cursor-pointer hover:bg-gray-100 
        ${isSelected ? 'bg-blue-50 text-blue-900' : 'text-gray-900'}`}
    >
      {children}
    </li>
  );
};

Select.Option = Option;

export default Select;