import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CustomLineChart = ({ data, config }) => {
  const [visibleStats, setVisibleStats] = useState(
    config.dataKeys.reduce((acc, key) => {
      acc[key] = true; // Initialize all lines as visible
      return acc;
    }, {})
  );

  // Toggle visibility of a line
  const toggleStat = (stat) => {
    setVisibleStats((prev) => ({ ...prev, [stat]: !prev[stat] }));
  };

  // Custom Legend with clickable labels
  const renderLegend = (props) => {
    const { payload } = props;

    // Ensure all legend items are always rendered
    const fullPayload = config.dataKeys.map((key, index) => ({
      dataKey: key,
      value: config.labels[index],
      color: config.colors[index],
      type: 'line', // Ensure the type is set to 'line'
    }));

    return (
      <div className="flex justify-center gap-4 mt-4">
        {fullPayload.map((entry, index) => (
          <span
            key={`legend-${index}`}
            onClick={() => toggleStat(entry.dataKey)}
            style={{
              cursor: 'pointer',
              color: visibleStats[entry.dataKey] ? entry.color : '#ccc', // Fade color when hidden
              opacity: visibleStats[entry.dataKey] ? 1 : 0.5, // Make text transparent when hidden
              textDecoration: visibleStats[entry.dataKey] ? 'none' : 'line-through', // Strikethrough when hidden
            }}
          >
            {entry.value}
          </span>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend content={renderLegend} />
        {config.dataKeys.map((key, index) =>
          visibleStats[key] ? (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              name={config.labels[index]}
              stroke={config.colors[index]}
              activeDot={{ r: 8 }}
            />
          ) : null
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;