import React, { useState } from 'react';
import { Phone, SquarePen, TrashIcon } from 'lucide-react';
import { DocsList, Tabs, Tab, EmployeeForm  } from "components";
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';
import { employeeService } from 'services';

const EmployeeItem = ({ item, refreshList }) => {
  const { openModal, closeModal } = useLayout();
  const { currentColor, showToast } = useStateContext();

  const handleEditEmployee = () => {
    openModal({
      component: <EmployeeForm employee={item}/>,
      title: "Изменить сотрудник",
    });
  };

  const handleDeleteEmployee = async () => {
    const confirmDelete = window.confirm(`Вы уверены, что хотите удалить сотрудника ${item.name}?`);
    if (confirmDelete) {
      const response = await employeeService.delete(item.id);
      if(response.status === 0) {
        showToast({
          title: 'Успешно!',
          content: `Сотрудник ${item.name} удален`,
          type: 'success',
        });
        closeModal();
        refreshList ? refreshList() : null;
      }
      else{
        showToast({
          title: 'Ошибка!',
          content: response.message || 'Что-то пошло не так!',
          type: 'error',
        });
      }

    }
  }


  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-xl font-semibold mb-1">{item.name}</h1> 
          <p className="text-gray-500 text-xs">Создан {item.created}</p> 
        </div>
        <div className="text-right space-x-2">
          <button className=" text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleEditEmployee()}>
            <SquarePen size={16} className="mr-1" /> 
          </button>
          <button className=" text-white px-4 py-2 rounded-md hover: bg-red-500" onClick={() => handleDeleteEmployee()}>
            <TrashIcon size={16} className="mr-1" />
          </button>
        </div>
      </div>

      {/* Contacts Section */}
      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">КОНТАКТЫ</h2> 
        </div>
        <div className="p-4">
          <div className="flex items-center gap-2">
            <Phone size={16} className="text-gray-500" />
            <a href={`tel:${item.phone}`} className="text-blue-600 text-sm"> 
              {item.phone}
            </a>
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">СТАТИСТИКА</h2> 
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Кол-во продаж</span> 
                <span className="text-sm">{item.stats?.count}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Себестоимость продаж</span> 
                <span className="text-sm">{item.stats?.purchase}</span> 
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Кол-во товаров</span> 
                <span className="text-sm">{item.stats?.quantity}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Прибыль продаж</span> 
                <span className="text-sm">{item.stats?.profit}</span> 
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Operations Section */}
      <div className="bg-white rounded-lg shadow-sm border">
        <Tabs initialTab="docs">
          <Tab key="docs" label="Движение товара">
            <DocsList consultant={item} />
          </Tab>
          <Tab key="sessions" label="Сессии">
            <div className="text-center text-gray-500 text-sm py-4">
              Нет данных о сессиях
            </div>
          </Tab>
          <Tab key="projects" label="Проекты">
            <div className="text-center text-gray-500 text-sm py-4">
              Нет данных о проектах
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default EmployeeItem;