import React from 'react';
import { List, ClientItem, ClientGroupSelector, ItemSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');
const ClientsList = (props) => {
  

  const filterOptions = [
    {
      key: 'last_sale__gte',
      label: 'Последняя продажа (с)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_sale__lt',
      label: 'Последняя продажа (до)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_notification__gte',
      label: 'Последнее уведомление (с)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_notification__lt',
      label: 'Последнее уведомление (до)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'bonus_balance__gte',
      label: 'Минимальный бонусный баланс',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          step="0.01"
          value={value}
          className={className}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      ),
      initialValue: '',
    },
    {
      key: 'bonus_balance__lt',
      label: 'Максимальный бонусный баланс',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          step="0.01"
          value={value}
          className={className}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      ),
      initialValue: '',
    },
    {
      key: 'clientgroup',
      label: 'Группа',
      field: ({value, onChange, className}) => (
        <ItemSelector
          targetUrl="/parties/admin-api/client-groups"
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите группу клиентов"
          searchPlaceholder="Поиск группы клиентов..."
          noItemsFoundText="Группы клиентов не найдены"
          isMultiSelect={true}
        />
      ),
      initialValue: props.clientgroup ? [props.clientgroup] : [],
    },
    {
      key: 'service',
      label: 'Мобильный оператор',
      field: ({ value, onChange, className }) => (
        <ItemSelector
          options={[
            { id: 'beeline', name: 'Билайн' },
            { id: 'o', name: 'О' },
            { id: 'megacom', name: 'Мегаком' },
          ]}
          onSelect={(item) => onChange([...value, item])}
          onRemove={(id) => onChange(value.filter((item) => item.id !== id))}
          selectedItems={value}
          placeholder="Выберите мобильного оператора"
          searchPlaceholder="Поиск..."
          noItemsFoundText="Операторы не найдены"
          isMultiSelect={true}
        />
        
      ),
      initialValue: [],
    }
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Имя' },
    { key: 'phone', label: 'Телефон' },
    { key: 'email', label: 'Электронная почта' },
    { key: 'bonus_balance', label: 'Бонусный баланс', component: (item) => <>{item.bonus_balance.toFixed(2)}</> },
    { 
      key: 'doc__count',
      label: 'Количество документов', 
      component: (item) => <>{item.docs?.meta.size}</> 
    },
    { key: 'last_sale', label: 'Последняя продажа', component: (item) => <>{formatDate(item.last_sale)}</> },
    { key: 'last_notification', label: 'Последнее уведомление', component: (item) => <>{formatDate(item.last_sms)}</> },
    { key: 'gender', label: 'Пол', component: (item) => <>{item.gender}</> },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Обновлено', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/parties/admin-api/clients"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ClientItem}
      
    />
  );
};
export default ClientsList;
