import React, { useState } from 'react';
import { Home, SquarePen } from 'lucide-react';
import { DocsList, Tabs, Tab, StoreForm  } from "components";
import { useLayout } from 'contexts/LayoutContext';
import { useStateContext } from 'contexts/ContextProvider';

const StoreItem = ({ item }) => {
  const { openModal } = useLayout();
  const { currentColor } = useStateContext();

  const handleEditStore = () => {
      openModal({
        component: <StoreForm store={item}/>,
        title: "Изменить сотрудник",
      });
    };

  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-xl font-semibold mb-1">{item.name}</h1> 
          <p className="text-gray-500 text-xs">Создан {item.created}</p> 
        </div>
        <div className="text-right">
          <button className=" text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleEditStore()}>
            <SquarePen size={16} className="mr-1" /> 
          </button>
        </div>
      </div>

      {/* Contacts Section */}
      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">Адрес</h2> 
        </div>
        <div className="p-4">
          <div className="flex items-center gap-2">
            <Home size={16} className="text-gray-500" />
            <p className="text-blue-600 text-sm"> 
              {item.address && item.address.meta.name}
            </p>
          </div>
        </div>
      </div>

      {/* Recent Operations Section */}
      <div className="bg-white rounded-lg shadow-sm border">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">ПОСЛЕДНИЕ ОПЕРАЦИИ</h2> 
        </div>
        {/* <div className="p-4">
          <div className="flex space-x-1 mb-4 bg-gray-100 p-1 rounded-lg">
            {['docs', 'money', 'bonus'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`flex-1 py-2 px-4 rounded-md text-xs font-medium transition-colors
                  ${activeTab === tab 
                    ? 'bg-white shadow-sm' 
                    : 'hover:bg-gray-50 text-gray-600'
                  }`}
              >
                {tab === 'docs' && 'Движение товара'}
                {tab === 'money' && 'Движение денег'}
                {tab === 'bonus' && 'История бонусов'}
              </button>
            ))}
          </div>

          {activeTab === 'docs' && (
            <DocsList filterString={"consultant=" + item.id} className="text-xs" />
          )}

          {(activeTab === 'money' || activeTab === 'bonus') && (
            <div className="text-center text-gray-500 text-sm py-4"> 
              Нет данных {activeTab === 'money' ? 'о движении денег' : 'об истории бонусов'}
            </div>
          )}
        </div> */}
        <Tabs initialTab="docs">
          <Tab key="docs" label="Движение товара">
            <DocsList filterString={"from_store=" + item.id} className="text-xs" />
          </Tab>
          <Tab key="money" label="Движение денег">
            <div className="text-center text-gray-500 text-sm py-4">
              Нет данных о движении денег
            </div>
          </Tab>
          <Tab key="bonus" label="История бонусов">
            <div className="text-center text-gray-500 text-sm py-4">
              Нет данных об истории бонусов
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default StoreItem;