import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Sector,
  Label
} from 'recharts';

const CustomPieChart = ({ data, config }) => {
  console.log('CustomPieChart', data, config);
  const [activeIndex, setActiveIndex] = useState(null);
  const [visibleSectors, setVisibleSectors] = useState({});

  // Initialize visible sectors
  useEffect(() => {
    if (data.length) {
      const initialState = data.reduce((acc, entry) => {
        acc[entry.name] = true;
        return acc;
      }, {});
      setVisibleSectors(initialState);
    }
  }, [data]); 

  // Create a map of name to color to maintain consistency
  const colorMap = React.useMemo(() => {
    return data.reduce((acc, entry, index) => {
      acc[entry.name] = config.colors[index % config.colors.length];
      return acc;
    }, {});
  }, [data, config.colors]);

  // Toggle visibility of a sector
  const toggleSector = (name) => {
    setVisibleSectors(prev => ({ ...prev, [name]: !prev[name] }));
  };

  // Filter visible data while maintaining original indices for color mapping
  const visibleData = data.filter(entry => visibleSectors[entry.name]);

  // Calculate total for percentages
  const total = visibleData.reduce((sum, entry) => sum + entry.value, 0);

  // Handle mouse enter for active animation
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  // Custom active shape with enhanced appearance
  const renderActiveShape = (props) => {
    const {
      cx, cy, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 8}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 8}
          outerRadius={outerRadius + 12}
          fill={fill}
        />
        <text
          x={cx}
          y={cy - 8}
          textAnchor="middle"
          fill="#333"
          className="text-sm font-medium"
        >
          {payload.name}
        </text>
        <text
          x={cx}
          y={cy + 8}
          textAnchor="middle"
          fill="#666"
          className="text-xs"
        >
          {`${value} (${(percent * 100).toFixed(1)}%)`}
        </text>
      </g>
    );
  };

  // Render label with percentage
  const renderLabel = (props) => {
    const { cx, cy, midAngle, outerRadius, percent, value, name } = props;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    const percentage = (value / total * 100).toFixed(1);
    
    return (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        className="text-xs"
      >
        {`${name} (${percentage}%)`}
      </text>
    );
  };

  // Custom Legend
  const renderLegend = () => (
    <div className="flex flex-wrap justify-center gap-4 mt-4">
      {data.map((entry) => (
        <span
          key={entry.name}
          onClick={() => toggleSector(entry.name)}
          className="cursor-pointer flex items-center gap-2"
          style={{
            color: visibleSectors[entry.name] ? colorMap[entry.name] : '#ccc',
            opacity: visibleSectors[entry.name] ? 1 : 0.5,
            textDecoration: visibleSectors[entry.name] ? 'none' : 'line-through',
          }}
        >
          <div
            className="w-3 h-3"
            style={{ backgroundColor: colorMap[entry.name] }}
          />
          {entry.name}
        </span>
      ))}
    </div>
  );

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={visibleData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={() => setActiveIndex(null)}
            label={renderLabel}
            labelLine={true}
          >
            {visibleData.map((entry) => (
              <Cell
                key={entry.name}
                fill={colorMap[entry.name]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      {renderLegend()}
    </div>
  );
};

export default CustomPieChart;