import React from 'react';
import { ClientGroupSelector, StoreSelector, List, ClientGroupItem } from 'components';

const formatDate = (date) => new Date(date).toLocaleDateString();

const ClientGroupsList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Имя',
      field:  (props) => (
        <input 
          type="text"
          placeholder="Наименование"
          value={props.value}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      initialValue: ''
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Наименование' },
    { key: 'members', label: 'Клиенты', component: (item) => <>{item.members && item.members.meta.size}</> },
    { key: 'created', label: 'Создано', component: (item) => <>{formatDate(item.created)}</> },
  ];

  return (
    <List
      targetUrl="/parties/admin-api/client-groups"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ClientGroupItem}
    />
  );
};

export default ClientGroupsList;
